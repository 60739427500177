import {
    Injectable,
    Component,
    OnInit} from '@angular/core';
import { MatSnackBar, MatDialogRef } from '@angular/material';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'login-dialog',
    template: `<login-form></login-form>`,
    styles: ['.full-width-block { width: 100%; display: block; }']
})
export class LoginDialogComponent implements OnInit {

    constructor(private dialogRef: MatDialogRef<LoginDialogComponent>) { }

    ngOnInit(): void {

    }

    close() {
        this.dialogRef.close();
    }
}

@Injectable()
export class AlertService {

    constructor() {   }

    static snackbar(snackBar: MatSnackBar, message: string, actionButtonText: string, autoDismiss?: boolean, duration?: number): any {
        if (!autoDismiss || autoDismiss == null) {
            autoDismiss = true;
        }

        actionButtonText = (!actionButtonText || actionButtonText == null) ? '' : actionButtonText;

        if (autoDismiss === true) {
            duration = duration || 2 * 1000;
            snackBar.open(message, actionButtonText, {duration: duration});
            return;
        }

        return snackBar.open(message, actionButtonText);
    }
}
