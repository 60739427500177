// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  apiUrl: 'https://api.tunti.cromsoft.com/', // http://localhost/project-input-api/public/ https://api.sigge.cromsoft.com/
  wsUrl: 'wss://api.tunti.cromsoft.com/ws',
  errorSnackbarDelay: 10 * 1000,
  hoursPerWeek: 37.5,
  exceptedHoursPerDay: 7.5,
  hourInputDropdownMax: 9,
  hourStep: 0.5, // 1 = 1 h, 0.5 = 30 min etc.
  daySpecificSettings: {
    enabled : false,
  },
  enableDeleteWeekRow: true,
  projectHeader: 'Project',
  workStageHeader: 'Workstage',
  colorWeekends: true,
  socketReconnectDelay: 5 * 1000, // In milliseconds
  locale: {
    short: 'fi',
    long: 'fi-FI'
  },
  lastuFormDelay: 1000,
  payperiod: {
    endDay: 23,
    markWeekends: false,
    markLessThanHours: 7.5
  },
  autocomplete: {
    minLength: 1, // characters
    itemLimit: 50 // null or 0 = no limit
  },
};
