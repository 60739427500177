import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { MatDatepickerInput } from '@angular/material';
import * as moment from 'moment';
import { FormControl } from '@angular/forms';
import { Observable ,  Observer } from 'rxjs';

@Component({
    selector: 'date-range-picker',
    template: `Date range picker demo:<br /><mat-form-field>
    <input matInput [matDatepicker]="rangePickerStart" class="date-range-input date-range-input-start" (input)="onDateInput('start')" (dateChange)="onDateChange('start')" [ngModel]="value.start | date:'yyyy-MM-dd'" [max]="valueStartMax" [formControl]="dateRangePickerStart">
    <mat-datepicker-toggle matSuffix [for]="rangePickerStart"></mat-datepicker-toggle>
    <mat-datepicker #rangePickerStart></mat-datepicker>
  </mat-form-field> - 
  <mat-form-field>
    <input matInput [matDatepicker]="rangePickerEnd" class="date-range-input date-range-input-end" (input)="onDateInput('end')" (dateChange)="onDateChange('end')" [ngModel]="value.end | date:'yyyy-MM-dd'" [min]="valueEndMin" [formControl]="dateRangePickerEnd">
    <mat-datepicker-toggle matSuffix [for]="rangePickerEnd"></mat-datepicker-toggle>
    <mat-datepicker #rangePickerEnd></mat-datepicker>
  </mat-form-field>`,
    styleUrls: ['./../app.component.css', 'date-range-picker.component.css']
})

export class DateRangePickerComponent implements OnInit, AfterViewInit {

    @Input() private datepicker: MatDatepickerInput<any>;
    valueStartMax: Date;
    valueEndMin: Date;
    dateRangePickerStart: FormControl;
    dateRangePickerEnd: FormControl;
    _observeChange: Observer<any>;
    _observeInput: Observer<any>;
    /** Returns start and end date. */
    value = { start: null, end: null };
    /** Emits when a `change` event is fired on these `<input>`s. */
    dateChange: Observable<any>;
    /** Emits when an `input` event is fired on these `<input>`s. */
    dateInput: Observable<any>;

    constructor() { }

    ngOnInit(): void {
        // Initialize dates here
        this.value.start = moment().startOf('day');
        this.value.end = moment().startOf('day').add(1, 'd');

        this.valueStartMax = this.value.end.toDate();
        this.valueEndMin = this.value.start.toDate();

        this.dateRangePickerStart = new FormControl();
        this.dateRangePickerEnd = new FormControl();
    }

    ngAfterViewInit(): void {
        this.dateChange = Observable.create((observe) => {
            this._observeChange = observe;
        });

        this.dateInput = Observable.create((observe) => {
            this._observeInput = observe;
        });
    }

    onDateChange(dateType) {
        this.minMaxUpdate(dateType);
        this._observeChange.next(this.value);
    }

    onDateInput(dateType) {
        this.minMaxUpdate(dateType);
        this._observeInput.next(this.value);
    }

    minMaxUpdate(dateType) {
        switch (dateType) {
            case 'start':
                const newStartVal = this.dateRangePickerStart.value;
                this.valueEndMin = newStartVal;
                break;

            case 'end':
                const newEndVal = this.dateRangePickerEnd.value;
                this.valueStartMax = newStartVal;
                break;
        }

        this.value.start = this.dateRangePickerStart.value;
        this.value.end = this.dateRangePickerEnd.value;
    }
}