import { ErrorHandler, Injectable, Injector} from '@angular/core';
import { ApiService } from './Api.service';
import { MatSnackBar } from '@angular/material';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  /**
   * Since error handling is really important it needs to be loaded first,
   * thus making it not possible to use dependency injection in the constructor
   * to get other services such as the error handle API service to send the server
   * our error details
   * @param injector
   */
  constructor(private injector: Injector, private snackBar : MatSnackBar) { }

  handleError(error : Error) {
     const apiService = this.injector.get(ApiService);
     let errorMessage

     errorMessage = error.name + "|" +error.message + "|" + error.stack;


     apiService.reportError(errorMessage);
     this.snackBar.open("Käsittelemätön virhe");

     // IMPORTANT: Rethrow the error otherwise it gets swallowed
     throw error;
  }

}
