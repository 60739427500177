import { Injectable } from '@angular/core';
import { IWeekEntryRow, IDayEntry } from './entity';
import { DateUtils } from './DateUtils.class';
import * as _ from 'lodash';
import { ApiService, SocketState } from './Api.service';
import { Subject, Observable, forkJoin, ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class ApiSyncService {

  changeQueue: ISyncContext[] = [];
  operationPending = false;

  public get pendingOperations(): number {
    return this.changeQueue.length;
  }

  operationComplete: Subject<any>  = new Subject<any> ();

  constructor(private apiService: ApiService) {
    this.apiService.socketConnectionStateChanged$.subscribe(
      state => {
        if(state == SocketState.Connected){
          this.next();
        }
      }
    );
  }
  public deleteRow(row: IWeekEntryRow, userId: string): Observable<any> {
    const deleteRowContext = new DeleteRowContext(Operation.Delete, row, userId);
    this.changeQueue.push(deleteRowContext);

    this.next();
    return deleteRowContext.callback;
  }
  public updateEntry(dayEntry: IDayEntry, userId: string): Observable<any> {
    const updateContext = new UpdateDayEntryContext(Operation.Update, dayEntry, userId);
    this.changeQueue.push(updateContext);
    this.next();
    return updateContext.callback;
  }
  public addDayEntry(dayEntry: IDayEntry, userId: string): Observable<any> {
    const createContext = new CreateDayEntryContext(Operation.Create, dayEntry, userId);
    this.changeQueue.push(createContext);
    this.next();
    return createContext.callback;
  }
  private next() {
    console.log("next");
    if (this.operationPending) {
      console.log('Waiting for pending operation');
      return;
    }

    this.operationPending = true;
    const nextOperation = this.changeQueue[0];
    if (nextOperation === undefined) {
      this.operationPending = false;
      console.log("next op is undefined");
      return;

    }
    let apiOperation;
    if (nextOperation instanceof CreateDayEntryContext) {
      console.log('operation: CreateDayEntry');
      apiOperation = this.apiService.addDayEntry(nextOperation.dayEntry, nextOperation.userId);
    } else if (nextOperation instanceof UpdateDayEntryContext) {
      console.log('operation: UpdateDayEntry');
      apiOperation = this.apiService.updateDayEntry(nextOperation.dayEntry, nextOperation.userId);
    } else if (nextOperation instanceof DeleteRowContext) {
      console.log('operation: DeleteRow');
      const apiOperations: Observable<any>[] = [];
      _.forEach(DateUtils.IsoWeekDays, dayIndex => {
        const dayEntry = (<DeleteRowContext>nextOperation).row.dayEntries[dayIndex];
        if (dayEntry.id !== null && dayEntry.id !== undefined) {
          apiOperations.push(this.apiService.deleteDayEntry(dayEntry));
        }
      });
      apiOperation = forkJoin(apiOperations);
    } else {
      console.error('Unknown Sync Operation');
      return;
    }
    apiOperation.subscribe(
      data => {

        /*if(!data || data.length == 0 || !data[0].Success){
          console.error("Sync Failed", data);
          nextOperation.callback.error("Sync operation failed. Retrying");
          this.apiService.reportError("Sync operation failed. Api returned: " + JSON.stringify(data));
          this.operationPending = false;
          this.next(); // Retry
          return;
        }*/
        console.log("API OP SUCCESS");
        if (nextOperation instanceof CreateDayEntryContext) {
          console.log(data[0]);
          nextOperation.dayEntry.id = data[0].Result.id;
        }
        nextOperation.callback.next({status : SyncStatus.OK, message : "OK"});
      },
      error => {
        console.log("API OPERATION ERROR");
        nextOperation.callback.next({status : SyncStatus.Error, message : JSON.stringify(error)});

        this.operationPending = false;
        if(this.apiService.currentSocketState != SocketState.Closed){
          this.next(); // Retry if socket is not disconnected. Otherwise wait for it to open again
        }

      },
      () => {
        console.log("api op complete");
        this.changeQueue.shift();
        this.operationPending = false;
        nextOperation.callback.complete();


        this.next();
      }
    );
  }


}
class DeleteRowContext implements ISyncContext {
  constructor(public operation: Operation, public row: IWeekEntryRow,
    public userId, public callback: ReplaySubject<ISyncStatusReport>= new ReplaySubject<any>(1)) {

  }
}
class UpdateDayEntryContext implements ISyncContext {

  constructor(public operation: Operation, public dayEntry: IDayEntry,
   public userId, public callback: ReplaySubject<ISyncStatusReport> = new ReplaySubject<any>(1)) {

  }
}
class CreateDayEntryContext implements ISyncContext {
  constructor(public operation: Operation, public dayEntry: IDayEntry,
    public userId, public callback: ReplaySubject<ISyncStatusReport> = new ReplaySubject<any>(1)) {

  }
}
interface ISyncContext {
  operation: Operation;
  userId;
  callback: ReplaySubject<any>;

}
export enum SyncStatus{
  OK,Error
}
export interface ISyncStatusReport{
  status : SyncStatus;
  message : string;
}
enum Operation {
  Update = 1,
  Create = 2,
  Delete = 3

}
