import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SpecialDay } from '../special-day.class';

@Component({
  template: `
    <h1 mat-dialog-title>{{header}}</h1>
    <mat-dialog-content>
        <div class="daydialog-checkbox"><mat-checkbox [(ngModel)]="day.sickleave" (change)="onChange()" (checked)="day.sickleave">Sick leave</mat-checkbox></div>
        <div class="daydialog-checkbox"><mat-checkbox [(ngModel)]="day.vacation" (change)="onChange()" (checked)="day.vacation">Vacation</mat-checkbox></div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-button (click)="updateDayEntries()">Ok</button>
    </mat-dialog-actions>
  `,
  styles: [
    '.daydialog-checkbox { padding: 14px 0 }'
  ]
})
export class DayDialogComponent {

  header: string;
  day: SpecialDay;

  constructor(private dialogRef: MatDialogRef<DayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data) {

    this.header = data.specialDay.date.toDate().toDateString();
    this.day = data.specialDay;
  }

  onChange() {
    this.day.modified = true;
  }

  updateDayEntries() {
    this.dialogRef.close(this.day);
  }
}