import { Component, AfterViewInit, OnInit, DoCheck, ViewChild, HostListener } from '@angular/core';
import { distinct, map, debounceTime, onErrorResumeNext, retryWhen, delay, filter, take, concat, concatMap } from 'rxjs/operators';
import { ContextMenuComponent } from '../contextmenu.component';
import * as moment from 'moment';
import { FormControl, Validators } from '@angular/forms';
import { Observable, forkJoin, PartialObserver, BehaviorSubject, throwError, of } from 'rxjs';
import { tap, switchMap } from 'rxjs/operators';
import { DateUtils } from './../DateUtils.class';
import { MatSnackBar, MatDialogRef, MatDialog, MatMenuTrigger, MatTooltip, MatIconRegistry, MatSnackBarRef } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import { ApiService, SocketState, IProjectEntry } from './../Api.service';
import * as _ from 'lodash';

import { Router } from '@angular/router';
import { AlertService } from '../_services/alert.service';
import { LoginService } from '../_services/login.service';
import { environment } from '../../environments/environment';
import { DayDialogComponent } from './daydialog.component';
import { LastuFormComponent } from '../lastu-form/lastu-form.component';
import { SessionService } from '../_services/session.service';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { PayperiodDialogComponent } from '../payperiod-dialog/payperioddialog.component';

import { Translator } from '../translator';

import { PayperiodReminderDialogComponent } from '../payperiod-reminder-dialog/payperiod-reminder-dialog.component';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Project, WorkstageGroup, WeekEntryRow, IWeekEntryRow, DayEntry, IDayEntry, PeriodInfo } from '../entity';
import { HourtableComponent, RowDeleteEventArgs } from '../hourtable/hourtable.component';
import { WorkStage } from '../weekentry.class';
import { EntryUpdaterService } from '../entry-updater.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { conditionallyCreateMapObjectLiteral } from '@angular/compiler/src/render3/view/util';
import { ChangeDetectionStrategy } from '@angular/compiler/src/core';
import { ApiSyncService, ISyncStatusReport, SyncStatus } from '../apisync.service';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpErrorResponse } from '@angular/common/http';


@Component({
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../../../node_modules/metro-dist/css/metro-icons.css'],

  providers: [
    { provide: MAT_DATE_LOCALE, useValue: environment.locale.long },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class HomeComponent implements AfterViewInit, OnInit {


  currentDateTime: moment.Moment = moment();
  rightPanelExpanded = true;

  projects$: BehaviorSubject<Project[]>;
  workstageGroups$: BehaviorSubject<WorkstageGroup[]>;
  workstages: WorkStage[];
  weekEntries$: BehaviorSubject<IWeekEntryRow[]>;
  currentPeriodInfo$: BehaviorSubject<PeriodInfo>;
  currentDate$: BehaviorSubject<moment.Moment>;
  formfields$: BehaviorSubject<any>;
  @ViewChild(HourtableComponent)
  hourTable: HourtableComponent;
  @ViewChild(LastuFormComponent)
  dayEntryForm: LastuFormComponent;

  calendarControl: FormControl = new FormControl(moment(),
    { validators: [Validators.required] });

  tableData: any;
  lastControl;
  rows: IWeekEntryRow[];
  defaultDayEntryValues;
  canAddRow: boolean;
  currentSyncState: SyncState = SyncState.Done;
  TEST: any;
  isInitializing = true;
  isLoading = false;
  currentUserName: string;
  users: any[] = [];
  currentUserId;
  debugLogging: boolean = false;
  invalidTableStateSnackbar: MatSnackBarRef<any> = null;
  socketErrorSnackBar: MatSnackBarRef<any> = null;
  numberOfPendingChanges: number = 0; //This number is changed for each hourtable change.

  constructor(private apiService: ApiService, public sessionService: SessionService,
    private EntryUpdaterService: EntryUpdaterService, public apiSyncService: ApiSyncService,
    iconRegistry: MatIconRegistry, sanitizer: DomSanitizer, private dialog: MatDialog, private router: Router, private snackBar: MatSnackBar) {

    moment.locale(environment.locale.long); //Set Global locale
    iconRegistry.addSvgIcon('baseline-done-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/baseline-done-24px.svg'));
    iconRegistry.addSvgIcon('baseline-error-icon', sanitizer.bypassSecurityTrustResourceUrl('assets/baseline-error-24px.svg'));
    this.currentUserName = sessionService.userName;


    this.weekEntries$ = new BehaviorSubject([]);
    this.projects$ = new BehaviorSubject([]);
    this.workstageGroups$ = new BehaviorSubject([]);
    this.formfields$ = new BehaviorSubject([]);
    this.currentPeriodInfo$ = new BehaviorSubject(null);
    this.currentDate$ = new BehaviorSubject(moment());
    this.currentUserId = sessionService.userId;
    this.calendarControl.valueChanges.pipe(map(v => {
      if (!this.calendarControl.valid || v == null) {
        return v;
      }
      const startOfWeek = moment(v).isoWeekday(1);
      this.calendarControl.setValue(startOfWeek, { emitEvent: false });
      return startOfWeek;

    }),
      // distinct((v : moment.Moment) => v.toISOString()))
    ).subscribe((v: moment.Moment) => {
      console.log('Change date');
      console.log(v);
      if (!this.calendarControl.valid || v == null) {
        return;
      }
      this.currentDate$.next(v);

    });


  }
  ngAfterViewInit(): void {

  }
  ngOnInit(): void {
    this.apiService.socketConnectionStateChanged$.subscribe(
      state => {
        if (state == SocketState.Closed) {
          if (this.socketErrorSnackBar == null) {
            this.socketErrorSnackBar = this.snackBar.open("Socket connection failed. Reconnecting...");
          }
          this.apiService.connectSocket();

        }
        else if (state == SocketState.Connected) {
          if (this.socketErrorSnackBar != null) {
            this.socketErrorSnackBar.closeWithAction();
          }
        }
      }
    );

    this.apiService.connectSocket();
    const projects = this.apiService.getProjects().pipe(
      tap(p => {
        this.projects$.next(p);
        this.hourTable.projects$.next(p);
      })
    );
    const workStages = this.apiService.getWorkStages().pipe(
      tap(w => this.workstageGroups$.next(w))
    );
    const formfields = this.apiService.getFormFields().pipe(
      tap(f => {
        this.formfields$.next(f);
        this.defaultDayEntryValues = f.reduce((accum, currentVal) => {
          accum[currentVal.formkey] = currentVal.defaultValue;
          return accum;
        }, {});
        console.log("Default entries", this.defaultDayEntryValues);
      })
    );
    const settings = this.apiService.getSettings(this.currentUserId).pipe(
      tap(s => {
        this.debugLogging = s.enableClientDebugLogging

      })
    );
    const periodInfo = this.apiService.getUserPeriodInfo(moment(), this.currentUserId).pipe(
      tap(p => { this.currentPeriodInfo$.next(p); })
    );
    const operators = [projects,
      workStages,
      formfields,
      settings,
      periodInfo];
    if (this.sessionService.isAllowedToAccessOtherUsers) {
      operators.push(this.apiService.getUsers().pipe(
        tap(u => this.users = u.filter(user => user.id !== this.sessionService.userId))
      ));
    }
    const initialization$ = forkJoin(
      operators
    );

    this.dayEntryForm.valueChanged$.subscribe(dayEntryUpdate => {
      this.onDayEntryFormValueChanged(dayEntryUpdate);
    });
    this.hourTable.valueChange$.pipe(
      tap(
        data =>  {
          this.numberOfPendingChanges++;
        }
      ),
      debounceTime(2000)
    ).subscribe(data => {
      
      if (!this.hourTable.isValid) {
        console.log("Invalid table state");
        if (this.invalidTableStateSnackbar == null) {
          this.invalidTableStateSnackbar = this.snackBar.open("Tiedoissa on virheitä!");
        }
        if (this.debugLogging) {
          this.apiService.reportDebug(this.hourTable.errors);
        }
      }else{
        this.onHourTableValueChange(data);
        if (this.invalidTableStateSnackbar != null) {
          this.invalidTableStateSnackbar.dismissWithAction();
        }
      }
      this.numberOfPendingChanges = 0;
      
    });
    this.hourTable.rowDeleted$.subscribe((args: RowDeleteEventArgs) => {
      console.log('delete row');
      this.currentSyncState = SyncState.Syncing;
      this.apiSyncService.deleteRow(args.weekEntryRow, this.currentUserId).subscribe(
        status => this.onSyncOperation(status),
        error => { },
        () => this.onSyncOperationComplete()
      );
    });
    this.hourTable.dayCellClicked$.subscribe(clickEvent => {
      const dayEntryFormValue = clickEvent.row[clickEvent.dayProperty];
      this.dayEntryForm.DayEntry$.next(dayEntryFormValue.dayEntry);
      if (this.hourTable.isDayReadOnly(dayEntryFormValue.dayEntry.day)) {
        this.dayEntryForm.readOnly = true;
      } else {
        this.dayEntryForm.readOnly = false;
      }
      console.log(this.hourTable.isDayReadOnly(dayEntryFormValue.dayEntry.day));


    });
    const subs = initialization$.pipe(
      retryWhen(
        errors => errors.pipe(
          tap(e => console.log('Initialization failed.Retrying...')),
          delay(2000),
          take(5),
          concatMap((e, i) => {
            console.log(e, i);
            this.snackBar.open("Initialization failed. Please reload the page.");
            return throwError(e);
          })
        )
      )
    ).subscribe(
      _ => { },
      errors => {
        if (errors.status === 401) {
          this.router.navigate(['/login']);
        }

      },
      () => {
        this.afterInit();
        this.isInitializing = false;

      }
    );
  }
  private onDayEntryFormValueChanged(dayEntryUpdate: any) {


    const dayEntry = this.dayEntryForm.DayEntry$.value;


    _.forEach(this.formfields$.value, field => {
      const property = field.formkey;
      dayEntry[property] = dayEntryUpdate[property];
    });
    const hasOnlyDefaultValues = this.hasDayEntryOnlyDefaultValues(dayEntry);
    console.log("AA " + hasOnlyDefaultValues);
    console.log(dayEntry);
    this.hourTable.setCellMoreDataFlag(dayEntry, !hasOnlyDefaultValues);


    if (dayEntry.id === null) {
      this.currentSyncState = SyncState.Syncing;
      this.apiSyncService.addDayEntry(dayEntry, this.currentUserId).subscribe(
        status => this.onSyncOperation(status),
        error => { },
        () => this.onSyncOperationComplete()
      );
      /**This prevents creating duplicate entries as application considers this entry to be created.
       * Sync service handles each request in order so this will get the id before next update.
       * TODO: Handle sync status in more explicit way.
       */
      dayEntry.id = -1;
    } else {
      this.currentSyncState = SyncState.Syncing;
      this.apiSyncService.updateEntry(dayEntry, this.currentUserId).subscribe(
        status => this.onSyncOperation(status),
        error => { },
        () => this.onSyncOperationComplete()
      );
    }
  }
  private payperiodClick() {
    console.log('payperiod click');

    const dg = this.dialog.open(PayperiodDialogComponent, {
      height: 'auto',
      width: '950px',
      data: {
        heading: DateUtils.getPayperiodStartAndEnd(this.currentDate$.value)[0].format('MMMM YYYY'),
        date: this.currentDate$.value,
        userId: this.currentUserId,
        parentComponent: this
      }
    });
    let dayChange = null;
    dg.componentInstance.onDateClicked$.subscribe(
      date => dayChange = date
    );
    dg.afterClosed().subscribe(() => {
      console.log('Payperioddialog closed');
      if (dayChange !== null) {
        this.calendarControl.setValue(dayChange);
      } else {
        this.setCurrentDate(this.currentDate$.value);
      }

    });
  }
  private onSyncOperationComplete() {
    console.log("SYNC OP COMPLETE");
    if (this.apiSyncService.changeQueue.length > 0) {
      console.log("SyncQueue", this.apiSyncService.changeQueue.length);
      this.currentSyncState = SyncState.Syncing;
    } else {
      this.currentSyncState = SyncState.Done;
    }

  }
  public get isUpdating() {
    
    return this.apiSyncService.changeQueue.length > 0 || this.numberOfPendingChanges > 0;
  }
  private onSyncOperation(status: ISyncStatusReport) {
    if (status.status == SyncStatus.Error) {
      console.log('SYNC ERROR');

      this.apiService.checkLoginStatus().subscribe(
        data => {
          this.apiService.reportDebug("Sync failed " + status.message);
          //this.snackBar.open("Sync failed.");
        },
        error => {
          this.snackBar.open("Session has expired. Please save any data and reload the page.");
        }
      );
    }

  }
  private logout() {
    this.router.navigate(['/login']);
  }
  private onHourTableValueChange(tableData: any[]) {


    console.log('HourTableValueChange');

    _.forEach(tableData, row => {

      const projectChanged = row.project !== row.weekEntryRow.project;
      const workstageChanged = row.workstage !== row.weekEntryRow.workstage;
      row.weekEntryRow.project = row.project;
      row.weekEntryRow.workstage = row.workstage;
      _.forEach(this.hourTable.dayIndexes, dayIndex => {
        if (this.hourTable.isDayReadOnly(dayIndex)) {
          console.log('Skipped update on ' + dayIndex + '. ReadOnly');
          return;
        }
        const dayCell = row['day' + dayIndex];
        const dayEntry = dayCell.dayEntry;

        let hasChanges = false;
        if (projectChanged || workstageChanged) {
          hasChanges = true;
        }
        if (projectChanged || dayEntry.projectId === null || dayEntry.projectId === undefined) {
          const project = this.getProjectByName(row.project);
          dayEntry.projectId = project !== undefined ? project.id : null;
        }
        if (workstageChanged || dayEntry.workstageId === null || dayEntry.workstageId === undefined) {
          const workstage = this.getWorkstageByName(row.workstage);
          dayEntry.workstageId = workstage !== undefined ? workstage.id : null;
        }
        if (dayCell.hours !== dayEntry.hours) {
          dayEntry.hours = dayCell.hours;
          hasChanges = true;
        }
        if (hasChanges) {
          if (dayEntry.id === null) {
            this.currentSyncState = SyncState.Syncing;
            this.apiSyncService.addDayEntry(dayEntry, this.currentUserId).subscribe(
              status => this.onSyncOperation(status),
              error => { },
              () => this.onSyncOperationComplete()
            );

            /**This prevents creating duplicate entries as application considers this entry to be created.
             * Sync service handles each request in order so this will get the id before next update.
             * TODO: Handle sync status in more explicit way.
             */
            dayEntry.id = -1;
            console.log('ADD DAY ENTRY');
          } else {
            this.currentSyncState = SyncState.Syncing;
            this.apiSyncService.updateEntry(dayEntry, this.currentUserId).subscribe(
              status => this.onSyncOperation(status),
              error => { },
              () => this.onSyncOperationComplete()
            );
          }
        }

      });
      if (projectChanged) {
        row.weekEntryRow.project = row.project;
      }
      if (workstageChanged) {
        row.weekEntryRow.workstage = row.workstage;
      }
    });
    this.tableData = tableData;
  }
  extractWorkstages() {
    this.workstages = [];
    const groups = this.workstageGroups$.getValue();
    _.forEach(groups, g => {
      _.forEach(g.workStages, w => {
        this.workstages.push(w);
      });
    });
  }
  setCurrentDate(date: moment.Moment) {
    if(this.isLoading){
      return;
    }
    this.isLoading = true;
    this.hourTable.setLoading(true);
    this.hourTable.clear();
    this.hourTable.currentDate = date;
    this.canAddRow = false;
    const daysInWeek = DateUtils.getAllDaysInWeek(date);
    const periodPoundary = DateUtils.getPayperiodStartAndEnd(date);

    const dateInfoQuerys = [];

    const periodsInWeek = [];
    const periodObservables: Observable<PeriodInfo>[] = [];
    const periodInfos: PeriodInfo[] = [];
    let hasMultipleYears = false;
    const weekStartDay = daysInWeek[0];
    const weekEndDay = daysInWeek[daysInWeek.length - 1];
    dateInfoQuerys.push(this.apiService.getProjectEntriesForWeek(weekStartDay.isoWeek(), parseInt(weekStartDay.format('YYYY')), this.currentUserId));
    // If week is between two years, fetch both.
    if (weekStartDay.year() !== weekEndDay.year()) {
      hasMultipleYears = true;
      dateInfoQuerys.push(this.apiService.getProjectEntriesForWeek(weekEndDay.isoWeek(), parseInt(weekEndDay.format('YYYY')), this.currentUserId));
    }



    for (let i = 0; i < daysInWeek.length; i++) {
      const dPeriod = DateUtils.getPayperiodStartAndEnd(daysInWeek[i]);
      const lPeriod = <[moment.Moment, moment.Moment]>periodsInWeek[periodsInWeek.length - 1];
      if (periodsInWeek.length === 0 || dPeriod[0].month() !== lPeriod[0].month()) {
        periodsInWeek.push(dPeriod);
        const query = this.apiService.getUserPeriodInfo(dPeriod[0], this.currentUserId).pipe(
          tap(info => periodInfos.push(info))
        );

        periodObservables.push(query);
        dateInfoQuerys.push(query);
      }
    }

    console.log(periodsInWeek);
    forkJoin(
      dateInfoQuerys
    ).subscribe(
      apiResults => {
        let entries;
        if (hasMultipleYears) {
          entries = apiResults[0].concat(apiResults[1]);
        } else {
          entries = apiResults[0];
        }


        const weekBoundary = DateUtils.getWeekStartAndEnd(date);
        console.log(periodInfos);
        console.log('Fetched entries for week ' + date.isoWeek()
          + ' (' + weekBoundary[0].toISOString() + '-' + weekBoundary[1].toISOString() + ')');

        const rows = this.parseProjectEntryRequest(entries);
        console.log(rows);
        const currentPeriod = this.currentPeriodInfo$.getValue();

        console.time('generate controls');

        this.hourTable.addRows(rows);
        console.timeEnd('generate controls');
        this.rows = rows;
        let readOnlyDays = 0;
        for (let i = 0; i < daysInWeek.length; i++) {
          const dPeriod = DateUtils.getPayperiodStartAndEnd(daysInWeek[i]);
          const periodInfo = periodInfos.find(p => p.startDate.month() === dPeriod[0].month());
          if (periodInfo.periodSubmitted) {
            this.hourTable.setDayReadOnly(daysInWeek[i], true);
            readOnlyDays++;
          }
        }
        if (readOnlyDays !== daysInWeek.length) {
          this.canAddRow = true;
        }
        console.log(readOnlyDays);

        //Set "more data" flags
        _.forEach(rows, row => {
          for (let i = 1; i < 8; i++) {
            const dayEntry = row.dayEntries[i];


            if (!this.hasDayEntryOnlyDefaultValues(dayEntry)) {
              this.hourTable.setCellMoreDataFlag(dayEntry, true);
            }
          }

        });
        this.hourTable.setLoading(false);
        this.isLoading = false;
      },
      error => { console.log(error); this.hourTable.setLoading(false);this.isLoading = false; }
    );


  }
  private hasDayEntryOnlyDefaultValues(dayEntry: IDayEntry): boolean {
    let hasOnlyDefaultValues = true;
    _.forOwn(this.defaultDayEntryValues, (value, key) => {
      if (dayEntry[key] != value) {
        //Skip empty strings
        if (typeof dayEntry[key] === "string" && dayEntry[key] === "") {
          return;
        }
        hasOnlyDefaultValues = false;
        console.log("Diff " + key, dayEntry[key], value);
        return false;
      }

    });
    return hasOnlyDefaultValues;
  }
  impersonateUser(user: any) {
    this.currentUserName = user.username;
    this.currentUserId = user.id;
    this.setCurrentDate(this.currentDate$.value);
  }
  removeImpersonate() {
    this.currentUserName = this.sessionService.userName;
    this.currentUserId = this.sessionService.userId;
    this.setCurrentDate(this.currentDate$.value);
  }
  afterInit() {
    this.extractWorkstages();

    this.currentDate$.pipe(

    ).subscribe(
      date => {
        console.log('date change');

        this.setCurrentDate(date);
      }
    );
  }
  updateDebugView(change) {
    if (change.row != null) {
      this.lastControl = change.properties.join(',') + '=>' + change.row.project + ' : ' + change.row.workstage + ' isValid:' + change.isValid;
    } else {
      this.lastControl = 'Global change';
    }
  }
  parseProjectEntryRequest(entries: IProjectEntry[]): IWeekEntryRow[] {
    console.time('parseProjectEntryRequest');
    const currentDate = this.currentDate$.value;
    const weekDays = DateUtils.getAllDaysInWeek(currentDate);

    let weekEntryRows: IWeekEntryRow[] = [];
    let i = 0;
    _.forEach(entries, (entry: IProjectEntry) => {
      console.log("Iteration: ", i, entry);
      const projectName = this.getProjectById(entry.projectId).displayName;
      const workstageName = this.getWorkstageById(entry.workstageId).name;
      const dayEntryIndex = entry.day.isoWeekday();
      //Lookup if project<=>workstage composite has open slot for this day
      let row = _.find(weekEntryRows, (row: IWeekEntryRow) =>
        projectName == row.project && workstageName == row.workstage && row.dayEntries[dayEntryIndex] === undefined
      );
      console.log("Found row", row !== undefined);

      if (row === undefined) {
        if (row !== undefined) {
          console.log("D entry ", row, row.dayEntries[dayEntryIndex]);
        }
        row = new WeekEntryRow();
        row.project = projectName;
        row.workstage = workstageName;
        weekEntryRows.push(row);
      }
      row.dayEntries[dayEntryIndex] = <any>entry;

    });
    // Fill dayentries missing data with default values
    _.forEach(weekEntryRows, row => {
      for (let i = 1; i <= 7; i++) {
        if (row.dayEntries[i] === undefined || row.dayEntries[i] == null) {
          const dayEntry = {
            ...this.defaultDayEntryValues,
            hours: 0,
            id: null,
            day: weekDays[i - 1]
          };

          row.dayEntries[i] = <DayEntry>dayEntry;
        }
      }
    });
    //Sort rows by project<=>workstage composite
    weekEntryRows = _.sortBy(weekEntryRows, ['project', 'workstage']);
    console.log("Rows", weekEntryRows);
    console.timeEnd('parseProjectEntryRequest');
    return weekEntryRows;
    /*
    // Group by project <=> workstage composite
    const projectGroups = _.groupBy(entries, entry => entry.workstageId.toString() + entry.projectId.toString());




    _.forEach(projectGroups, (dayEntries, key) => {
      const projectId = dayEntries[0].projectId;
      const workstageId = dayEntries[0].workstageId;
      const dayGroups = _.groupBy(dayEntries, entry => entry.day);
      const row = new WeekEntryRow();
      row.project = this.getProjectById(projectId).displayName;
      row.workstage = this.getWorkstageById(workstageId).name;

      // Fill dayentries with data
      _.forEach(dayGroups, (dayEntries, date) => {
        const dayEntry = <IDayEntry>dayEntries[0];
        dayEntry.day = moment(dayEntry.day);

        row.dayEntries[dayEntry.day.isoWeekday()] = dayEntry;
      });

      // Fill dayentries missing data with default values
      for (let i = 1; i <= 7; i++) {
        if (row.dayEntries[i] === undefined || row.dayEntries[i] == null) {
          const dayEntry = {
            ...this.defaultDayEntryValues,
            hours: 0,
            id: null,
            day: weekDays[i - 1]
          };

          row.dayEntries[i] = <DayEntry>dayEntry;
        }
      }

      weekEntryRows.push(row);
    });
    console.timeEnd('parseProjectEntryRequest');
    return weekEntryRows;*/
  }

  addWeek(direction: number) {

    const newDate = moment(this.currentDate$.value);
    newDate.add(direction, 'week');
    this.calendarControl.setValue(newDate);

  }

  hourTableValueChanged(event) {
    console.log('Value Change event');
    console.log(event);
  }
  addRow() {
    // TODO add limit to number of rows
    const currentDate = this.currentDate$.value;
    const row = this.createDefaultWeekEntryRow(currentDate);
    this.hourTable.addRow(row);
  }
  createDefaultWeekEntryRow(date: moment.Moment): WeekEntryRow {
    const row = new WeekEntryRow();
    row.dayEntries = [];
    const currentDate = this.currentDate$.value;
    const weekDays = DateUtils.getAllDaysInWeek(currentDate);
    const d = moment().startOf('isoWeek');
    for (let i = 1; i <= 7; i++) {
      const dayEntry = {
        ...this.defaultDayEntryValues,
        hours: 0,
        id: null,
        day: weekDays[i - 1]
      };
      row.dayEntries[i] = <DayEntry>dayEntry;
    }
    return row;
  }
  getProjectById(id: number): Project {
    return this.projects$.value.find(item => item.id === id);
  }
  getWorkstageById(id: number): WorkStage {
    return this.workstages.find(item => item.id === id);
  }
  getProjectByName(name: string) {
    return this.projects$.value.find(item => item.displayName === name);
  }
  getWorkstageByName(name: string) {
    return this.workstages.find(w => w.name === name);
  }
  printRows() {
    console.log(this.hourTable.getRows());
  }
  test() {
    console.log('click');
  }
  leftSideClick(event) {
    if (event.target.id === 'left-side') {
      this.dayEntryForm.DayEntry$.next(null);
      this.hourTable.clearCellSelection();
    }
  }
  changeCalendarDate(event: MatDatepickerInputEvent<moment.Moment>) {
    console.log(event.value);
  }
  toggleRightPanel() {
    this.rightPanelExpanded = !this.rightPanelExpanded;
  }
  @HostListener('window:beforeunload', ['$event'])
  public unsavedChangesWarning($event) {
    if (this.apiSyncService.changeQueue.length > 0) {
      return false;
    }
  }
}
enum SyncState {
  Done = 0, Syncing = 1, Error = 2
}
