import * as _ from 'lodash';
import * as moment from 'moment';


export class DateUtils {

  static readonly IsoWeekDays = [1,2,3,4,5,6,7];

  // Gets all week days in given date
  static getAllDaysInWeek(date: moment.Moment): Array<moment.Moment> {
    const result = [];
    _.times(7, (num) => {
      const startOfWeek = moment(date);
      startOfWeek.startOf('isoWeek');
      startOfWeek.add(num, 'd');
      result.push(startOfWeek);
    });
    return result;
  }

  static getAllDaysInMonth(year, month): moment.Moment[] {
    const x = year + '-' + month + '-1',
      day = moment(x, 'YYYY-MM-DD'),
      dates: moment.Moment[] = [];

    while (day.month() < month) {
      const d = moment(day.add(1, 'd'));
      dates.push(d);
    }
    return dates;
  }
  static getWeekStartAndEnd(date : moment.Moment) : [moment.Moment, moment.Moment]{
    let start = moment(date).isoWeekday(1);
    let end = moment(date).isoWeekday(7);
    return [start,end];
  }
  static getPayperiodStartAndEnd(date: moment.Moment, payPeriodEndDay: number = 23): [moment.Moment, moment.Moment] {

    let periodStart: moment.Moment;
    let periodEnd: moment.Moment;

    if (date.date() <= payPeriodEndDay) {
      periodStart = moment(date);
      periodStart.subtract(1, 'months');
      periodStart.date(payPeriodEndDay + 1);
      periodEnd = moment(date);
      periodEnd.date(payPeriodEndDay);
    } else {
      periodStart = moment(date);
      periodStart.date(payPeriodEndDay + 1);
      periodEnd = moment(date);
      periodEnd.add(1, 'months');
      periodEnd.date(payPeriodEndDay);
    }

    return [periodStart, periodEnd];
  }

  static getAllIsoWeeksInPeriod(start: moment.Moment, end: moment.Moment): [number, number][] {
    end = moment(end).add(1, 'day');

    const currentDate = moment(start),
    result: [number, number][] = []; // year,isoWeek

    // Push first isoWeek
    result.push([parseInt(currentDate.format('GGGG')), currentDate.isoWeek()]);
    while (!currentDate.isSame(end, 'day')) {
      const currentIsoWeek = currentDate.isoWeek(),
        currentYear = parseInt(currentDate.format('GGGG')),
        last = result[result.length - 1];

      if (last[0] !== currentYear || last[1] !== currentIsoWeek) {
        result.push([parseInt(currentDate.format('GGGG')), currentDate.isoWeek()]);
      }
      currentDate.add(1, 'day');
    }
    return result;

  }

  static getPayperiodName(date: moment.Moment, payPeriodEndDay: moment.Moment) {
    if (date.isAfter(payPeriodEndDay)) {
      date.add(1, 'months');
    }

    return date.format('MMMM YYYY');
  }
  static isWeekend(isoWeekDay : number){
    return isoWeekDay == 6 || isoWeekDay == 7;
  }
  static weekdayFilter(day: number): boolean {
    return day < 5;
  }
  static getCurrent() :moment.Moment{
    return moment();
  }
}
