import { Component, ViewChild, OnInit } from "@angular/core";
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';

@Component({
    selector: 'contextmenu',
    template: `<button id="contextMenuTrigger" #contextMenuTrigger [matMenuTriggerFor]="contextMenu"></button>
    <mat-menu #contextMenu="matMenu" class="contextmenu-panel">
        <button mat-menu-item *ngFor="let item of itemList" (click)="item.callback()"
        [disabled]="item.disabled"><mat-icon *ngIf="item.matIcon != null">{{item.matIcon}}</mat-icon>
          <span class="empty-mat-icon mat-icon" *ngIf="(item.matIcon == null || item.matIcon == undefined) && icons == true"></span>
          <span class="menu-item-text">{{item.title}}</span>
        </button>
    </mat-menu>`,
    styles: ['#contextMenuTrigger { display: none; width: 0; height: 0; padding: 0; border: none; background: none; visibility: hidden; }',
    '.mat-menu-item { font-size: 75%; line-height: calc(48px * .75); height: calc(48px * .75); padding: 0 calc(16px * .75); }',
    '.empty-mat-icon { display: inline-block; margin-left: 0; }', '.mat-icon { margin-right: calc(16px * .75); }',
    '.menu-item-text { display: inline-block; margin: 0; vertical-align: middle; }']
})

export class ContextMenuComponent implements OnInit {
    itemList: IContextMenuItem[];
    private menuPosition: IContextMenuPosition;
    icons: boolean;
    @ViewChild('contextMenuTrigger', { read: MatMenuTrigger }) contextMenuTrigger: MatMenuTrigger;
    @ViewChild('contextMenu', { read: MatMenu }) contextMenu: MatMenu;

    constructor() {
        this.itemList = [];
        this.icons = false;
    }

    ngOnInit() {
    }

    get position() {
        return this.menuPosition;
    }

    set position(position: IContextMenuPosition) {
        return;
    }

    get items() {
        return this.itemList;
    }

    set items(items: IContextMenuItem[]) {
        this.itemList = items;
        this.icons = !this.itemList.every(item => item.matIcon == null || item.matIcon == undefined || item.matIcon.length == 0);
    }

    isOpened(): boolean {
        return this.contextMenuTrigger.menuOpen;
    }

    open(event: MouseEvent) {
        const pos: IContextMenuPosition = { x: event.pageX, y: event.pageY };
        this.menuPosition = pos;

        if (window.getSelection) {

            let menu = document.getElementById('contextMenuTrigger');
            menu.style.display = 'block';
            menu.style.position = 'absolute';
            menu.style.left = event.pageX + 5 + 'px';
            menu.style.top = event.pageY + 5 + 'px';

            this.contextMenuTrigger.openMenu();
        }
    }

    close() {
        if (window.getSelection) {
            let menu = document.getElementById('contextMenuTrigger');
            menu.style.display = 'none';
        }

        if (this.isOpened()) {
            this.contextMenuTrigger.closeMenu();
        }
    }
}

export interface IContextMenuItem {
    title: string;
    matIcon?: string;
    disabled?: boolean;
    callback: () => any;
}

export class ContextMenuItem implements IContextMenuItem {
    title: string;
    matIcon: string = null;
    disabled: boolean = false;
    callback: () => any;
}

export interface IContextMenuPosition {
    x: number;
    y: number;
}

export class ContextMenuPosition implements IContextMenuPosition {
    x: number;
    y: number;
}
