import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subject, ReplaySubject, } from 'rxjs';
import { WebSocketSubject } from 'rxjs/webSocket';
import { retry, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { environment } from '../environments/environment';
import { MatSnackBar } from '@angular/material';
import * as moment from 'moment';
import { Translator } from './translator';
import { SocketService } from './_services/socket.service';
import { Project, WorkstageGroup, IDayEntry, PeriodInfo } from './entity';

export enum SocketState{
  Connected, Closed
}
@Injectable()
export class ApiService {

  settingsUrl = environment.apiUrl + 'settings';
  formFieldUrl = environment.apiUrl + 'collection/formfield';
  hoursUrl = environment.apiUrl + 'hours';
  totalUrl = environment.apiUrl + 'hours/totals/week';
  balanceUrl = this.hoursUrl + '/balance';
  projectUrl = environment.apiUrl + 'collection/project';
  projectHistoryUrl = environment.apiUrl + 'hours/history/projects';
  workstageUrl = environment.apiUrl + 'collection/workstage';
  usersUrl = environment.apiUrl + 'collection/user';
  loginUrl = environment.apiUrl + 'login';
  daysUrl = environment.apiUrl + 'days';
  payperiodUrl = environment.apiUrl + 'payperiod';
  checkLoginStatusUrl = environment.apiUrl + 'login/status';
  periodReportUrl = environment.apiUrl + 'report/period';
  errorReportUrl = environment.apiUrl + 'log/error';
  debugReportUrl = environment.apiUrl + 'log/debug';
  requestIdCounter = 0;
  socket: WebSocketSubject<any>;
  socketRequestContexts: SocketRequestContext[] = [];

  socketConnectionStateChanged$ : Subject<SocketState> = new Subject();
  currentSocketState : SocketState = SocketState.Closed;

  constructor(private http: HttpClient, public snackBar: MatSnackBar, translator: Translator, public socketService: SocketService) {



    console.log('Api Service constructed with url ' + environment.apiUrl);

    translator.locale = environment.locale.short;

  }


  public connectSocket(){

    const url = environment.wsUrl + '?access_token=' + localStorage.getItem('jwt');

    if(this.currentSocketState == SocketState.Connected){
      console.log("socket not closed", this.socket);
      return;
    }

    this.socket = new WebSocketSubject({
      url,
      openObserver: {
        next: () => {
          console.log("socket connected");
          this.currentSocketState = SocketState.Connected;
          this.socketConnectionStateChanged$.next(SocketState.Connected);

        }
      },
      closeObserver: {
        next : () => {
          console.log("socket closed");
          this.currentSocketState = SocketState.Closed;
          const callbacks = this.socketRequestContexts;
          this.socketRequestContexts = []; // Clear pending requests
          _.forEach(callbacks, context => {
            context.callback.error("Closed");
          });


          this.socketConnectionStateChanged$.next(SocketState.Closed);


        }
      }


    });

    this.socket.subscribe(
      data => {
        console.log('DATA');
        console.log(data);
        this.onSocketReceive(data);
      },
      error => {
        console.error("Socket error",error);


      },
      () => { console.log('socket complete'); }
    );

  }
  private onSocketReceive(data: any) {
    const socketResponse = <SocketResponse>data;
    const context = this.socketRequestContexts.find(s => s.requestId === socketResponse.RequestId);
    if (context === undefined) {
      console.warn('Could not find SocketRequestContext for response \n' + JSON.stringify(data));
      return;
    }
    context.callback.next(socketResponse.ResponseData);
    context.callback.complete();
    // Remove from contexts
    this.socketRequestContexts = this.socketRequestContexts.filter(s => s.requestId !== socketResponse.RequestId);
  }
  getProjects(): Observable<Project[]> {
    return this.http.get<Project[]>(this.projectUrl, { headers: this.getHeaders() });
  }

  getProjectHistory(): Observable<any> {
    return this.http.get(this.projectHistoryUrl, { headers: this.getHeaders() }); /*.pipe(
      catchError(this.handleError('getProjects', []))
    );*/
  }

  getWorkStages(): Observable<WorkstageGroup[]> {
    return this.http.get<WorkstageGroup[]>(this.workstageUrl, { headers: this.getHeaders() });
  }

  getFormFields(): Observable<any[]> {
    return this.http.get<any>(this.formFieldUrl, { headers: this.getHeaders() });
  }

  getSettings(userId: number): Observable<any> {
    const params = new HttpParams()
      .set('userid', userId.toString());

    return this.http.get(this.settingsUrl, { params: params, headers: this.getHeaders() }); /*.pipe(
      catchError(this.handleError('getFormFields', []))
    );*/
  }
  getUsers(): Observable<any> {
    return this.http.get(this.usersUrl, { headers: this.getHeaders() });
  }
  getDays(isoWeek: number, year: number, userId: number): Observable<any> {
    const params = new HttpParams()
      .set('isoweek', isoWeek.toString())
      .set('year', year.toString())
      .set('userid', userId.toString());

    return this.http.get(this.daysUrl, { params: params, headers: this.getHeaders() }); /*.pipe(
      catchError(this.handleError('getFormFields', []))
    );*/
  }

  getPeriodReport(date: moment.Moment,userId : number, reportProgress: boolean = false): Observable<any> {
    const token = localStorage.getItem('jwt');


    return this.http.get(this.periodReportUrl +"?Period="+ encodeURIComponent(date.toISOString(true))+ "&userId="+userId,
      {
        observe: 'response',
        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/pdf',
          'Accept': 'application/pdf',
          'Accept-Language': environment.locale.long
        },
        responseType: 'blob',
        reportProgress: reportProgress
      });
  }
  checkOutPeriod(date: moment.Moment, userId: number): Observable<any> {
    const json = { date: date.toJSON(), userid: parseInt(userId.toString(), 10) };

    return this.http.post(this.payperiodUrl, JSON.stringify(json), { headers: this.getHeaders() });
  }


  getUserPeriodInfo(date: moment.Moment, userId: number): Observable<PeriodInfo> {
    const params = new HttpParams()
      .set('date', date.format('YYYY-MM-DD'))
      .set('userid', userId.toString());

    return this.http.get<PeriodInfo>(this.payperiodUrl, { params: params, headers: this.getHeaders() }).pipe(
      map(x => {
        x.startDate = moment(x.startDate);
        x.endDate = moment(x.endDate);
        return x;
      })
    );
  }


  login(username: string, password: string): Observable<any> {
    const body = { username: username, password: password },
      headers = { 'Content-Type': 'application/json', 'Accept-Language': environment.locale.long };

    return this.http.post(this.loginUrl, body, { headers: headers });
  }
  public checkLoginStatus(): Observable<any> {
    return this.http.get<any>(this.checkLoginStatusUrl, { headers: this.getHeaders() });
  }

  getProjectEntriesForWeek(isoWeek: number, year: number, userId: number): Observable<IProjectEntry[]> {
    const params = new HttpParams()
      .set('isoweek', isoWeek.toString())
      .set('year', year.toString())
      .set('userid', userId.toString());

    return this.http.get<IProjectEntry[]>(this.hoursUrl, { params: params, headers: this.getHeaders() }).pipe(map(
    entries => {
        //Map dates to moment instance
        _.forEach(entries, entry => {
          entry.day = moment(entry.day);
        });
        return entries;}
    )); /*.pipe(
      catchError(this.handleError('getProjectEntriesForWeek', []))
    );*/
  }

  getTotalHoursForWeek(isoWeek: number, year: number, userId: string): Observable<any> {
    const params = new HttpParams()
      .set('isoweek', isoWeek.toString())
      .set('year', year.toString())
      .set('userId', userId);

    return this.http.get(this.totalUrl, { params: params, headers: this.getHeaders() });
    /*.pipe(
      catchError(this.handleError('getProjectEntriesForWeek', []))
    );*/
  }

  getUserBalance(userId: string): Observable<any> {
    const params = new HttpParams()
      .set('userId', userId);

    return this.http.get(this.balanceUrl, { params: params, headers: this.getHeaders() });
    /*.pipe(
      catchError(this.handleError('getProjectEntriesForWeek', []))
    );*/
  }
  getHeaders() {
    const token = localStorage.getItem('jwt');

    return {
      'Authorization': 'Bearer ' + token,
      'Content-Type': 'application/json',
      'Accept-Language': environment.locale.long
    };
  }
  public updateDayEntry(entry: IDayEntry, userId: string): Observable<any> {
    const requestData = <any>{
      ...entry,
      userId
    };
    /*TODO This formats the moment date to local format instead of UTC.
      The api should expect UTC date.
    */
    requestData.day = requestData.day.format('YYYY-MM-DD');
    const request = this.createSocketRequest('hourentry', [{
      data: requestData,
      operation: CrudOperationType.Update,
      operationCallbackIdentifier: null
    }]);
    return this.sendSocketRequest(request);
  }
  public deleteDayEntry(entry: IDayEntry): Observable<any> {
    const request = this.createSocketRequest('hourentry', [{
      data: entry,
      operation: CrudOperationType.Delete,
      operationCallbackIdentifier: null
    }]);
    return this.sendSocketRequest(request);
  }
  public addDayEntry(entry: IDayEntry, userId: string): Observable<any> {
    const requestData = <any>{
      ...entry,
      userId
    };
    delete requestData['id'];
    /*TODO This formats the moment date to local format instead of UTC.
      The api should expect UTC date.
    */
    requestData.day = requestData.day.format('YYYY-MM-DD');
    const request = this.createSocketRequest('hourentry', [{
      data: requestData,
      operation: CrudOperationType.Create,
      operationCallbackIdentifier: null
    }]);
    return this.sendSocketRequest(request);
  }
  private createSocketRequest(request: string, data: any): SocketRequest {

    return ({
      Request: request,
      RequestId: (++this.requestIdCounter).toString(),
      RequestData: data
    });
  }
  private sendSocketRequest(request: SocketRequest): Observable<any> {

    const requestContext: SocketRequestContext = {
      callback: new ReplaySubject<any>(1),
      requestId: request.RequestId
    };
    if(this.currentSocketState == SocketState.Closed){
      requestContext.callback.error("Socket is closed");
      return requestContext.callback;

    }
    this.socketRequestContexts.push(requestContext);
    // Rxjs websocket automatically parses request to json
    this.socket.next(request);
    console.log("Request send", request);
    return requestContext.callback;
  }
  public reportError(error: any) {
    if (typeof error !== "string") {
      error = JSON.stringify(error);
    }
    let errorData = {
      error
    };
    this.http.post(this.errorReportUrl, JSON.stringify(errorData), { headers: this.getHeaders() }).subscribe();
  }
  public reportDebug(debugMessage: any) {
    if (typeof debugMessage !== "string") {
      debugMessage = JSON.stringify(debugMessage);
    }
    let debugData = {
      Message: debugMessage
    };
    this.http.post(this.debugReportUrl, JSON.stringify(debugData), { headers: this.getHeaders() }).subscribe();
  }
}
class SocketRequest {
  Request: string;
  RequestId: string;
  RequestData: any;
}
class SocketRequestContext {
  callback: ReplaySubject<any>;
  requestId: string;
}
class SocketResponse {
  RequestId: string;
  ResponseCode: number;
  ResponseData: any;
}
enum CrudOperationType {
  Create = 0, Read = 1, Update = 2, Delete = 3
}
export interface IProjectEntry{
  id :number;
  projectId : number;
  workstageId : number;
  hours: number;
  day: moment.Moment;

}
