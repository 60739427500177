import { Component, Inject, OnInit, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSnackBar, MatDialog, MatSnackBarRef, SimpleSnackBar } from '@angular/material';
import { MatTableDataSource } from '@angular/material';
import * as moment from 'moment';
import { DateUtils } from '../DateUtils.class';
import { ApiService } from '../Api.service';
import { environment } from '../../environments/environment';
import { forkJoin, Subject, Observable } from 'rxjs';
import { LoginService } from '../_services/login.service';
import { AlertService } from '../_services/alert.service';
import { Translator } from '../translator';
import { Chart } from 'chart.js';
import * as chartJsPluginAnnotation from 'chartjs-plugin-annotation';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { saveAs } from 'file-saver';
import { PeriodInfo } from '../entity';


enum StartDay {
  Sunday, Monday
}

@Component({
  templateUrl: './payperioddialog.component.html',
  styleUrls: ['./payperioddialog.component.css', '../../../node_modules/metro-dist/css/metro-icons.css'],
})

export class PayperiodDialogComponent implements OnInit, AfterViewInit {
  displayedColumns: string[];
  rows: PayperiodWeekEntryRow[];
  tableSource: MatTableDataSource<PayperiodWeekEntryRow>;
  heading: string;
  date: moment.Moment;
  period: PeriodRange;
  startDay: StartDay;
  periodTotalHours: number;
  periodInfo: PeriodInfo;
  periodCheckedOut: boolean;
  chart: Chart;
  chartData: { days: any[]; hours: any[]; };
  private readonly chartMax: number;
  periodRequiredHours: number;
  reportBtnEnabled = true;
  canCheckoutPeriod : boolean;
  public onDateClicked$ = new Subject<moment.Moment>();
  periodBalance: any;

  constructor(private dialogRef: MatDialogRef<PayperiodDialogComponent>, private dialog: MatDialog,
    private apiSvc: ApiService, private loginService: LoginService, public snackBar: MatSnackBar,
    public translator: Translator, @Inject(MAT_DIALOG_DATA) private data) {

    this.displayedColumns = ['week', '1', '2', '3', '4', '5', '6', '7'];
    this.date = moment(data.date);
    this.rows = [];
    this.startDay = StartDay.Monday;
    this.periodCheckedOut = false;
    this.heading = data.heading.substr(0, 1).toUpperCase() + data.heading.substr(1);
    this.chartData = { days: [], hours: [] };
    this.chartMax = 15;


  }

  ngOnInit(): void {
    this.refreshUI(true);
  }
  ngAfterViewInit(): void {

  }
  gotoPeriod(num: number) {
    if (num < 0) {
      this.date.subtract(1, 'month');
    } else {
      this.date.add(1, 'month');
    }

    this.refreshUI();
  }

  refreshUI(init: boolean = false) {
    // Reset total hours
    this.periodTotalHours = 0;

    // Get start and end of the period
    const period = DateUtils.getPayperiodStartAndEnd(this.date, environment.payperiod.endDay);
    period[0].startOf('day');
    period[1].startOf('day');
    this.period = new PeriodRange(period[0], period [1]);
    // Get heading (period name)
    const heading = DateUtils.getPayperiodName(this.date, this.period.end);
    this.heading = this.period.end.format("MMMM");

    // Init weeks
    let weeks = DateUtils.getAllIsoWeeksInPeriod(this.period.start, this.period.end);
    console.log(weeks);
    this.initWeeks(weeks, init);
  }

  initBalance() {
    const { apiSvc, data: { userId }} = this;

    apiSvc.getUserBalance(userId).subscribe(({ balance }) => {
      this.periodBalance = balance;
    });
  }

  initWeeks(weeks: any, init: boolean = false) {
    this.initBalance();

    // Clear old rows and create empty array for observables
    this.rows = [];
    const observables = [];

    observables.push(this.apiSvc.getUserPeriodInfo(moment(this.date), this.data.userId));

    weeks.forEach(week => {
      observables.push(this.apiSvc.getTotalHoursForWeek(week[1], week[0], this.data.userId));
      this.rows.push({ isoWeek: week[1], days: [] });
    });

    console.log("Period: ", this.period.start.toString(), this.period.end.toString());
    forkJoin(observables).subscribe(results => {
      const periodInfo = results.shift();
      this.periodInfo = periodInfo;
      this.canCheckoutPeriod = this.periodInfo.missingRequiredEntries.length === 0 && !this.periodInfo.periodSubmitted;
      this.periodRequiredHours = periodInfo.requiredHours;
      results.forEach((result: any) => {


        result.days.forEach((day, _i) => {
          day.date = moment(day.date);

          const newDay: PayperiodDayEntry = day;
          const isBefore = newDay.date.isBefore(this.period.start);
          const isAfter = newDay.date.isAfter(this.period.end);
          newDay.otherPeriod = isBefore || isAfter;

          if(!newDay.otherPeriod){
            //console.log("Not Other period", newDay.date.toString(), day.hoursTotal,isBefore, isAfter);
            this.periodTotalHours += day.hoursTotal;
          }
          const week = this.rows.find(r => r.isoWeek === newDay.date.isoWeek());

          if (week) {
            let index = newDay.date.day();

            if (this.startDay === StartDay.Monday) {
              if (newDay.date.day() === 0) {
                index = 6;
              } else {
                index -= 1;
              }
            }

            week.days[index] = newDay;
          }
        });
      });
    },
      error => {
        // Error 401 (Unauthorized) -> show login dialog
        console.error(error);
      },
      () => {
        this.tableSource = new MatTableDataSource<PayperiodWeekEntryRow>(this.rows);


        this.refreshChartData();

        if (!this.chart) {
          this.initChart();
        } else {
          this.chart.data.labels = this.chartData.days.map(d => d.format('ddd D.M.'));
          this.chart.data.datasets[0].data = this.chartData.hours;
        }

        const dataset = this.chart.data.datasets[0];

        dataset.data.forEach((item, i) => {
          const color = (item < environment.exceptedHoursPerDay) ? '#ba3c3c' : '#3cba9f';
          dataset.backgroundColor[i] = color;

          const hoverColor = (item < environment.exceptedHoursPerDay) ? '#c94b4b' : '#4bc9ae';
          dataset.hoverBackgroundColor[i] = hoverColor;
        });

        this.chart.update();
      });
  }
  getPeriodDates(): moment.Moment[] {
    const dates: moment.Moment[] = [];

    if (this.period) {
      const startDate: moment.Moment = Object.assign(this.period.start);

      while (startDate.isSameOrBefore(this.period.end)) {
        dates.push(moment(startDate.format('YYYY-MM-DD')));
        startDate.add(1, 'days');
      }
    }

    return dates;
  }
  getPeriodDateTotals(dates: moment.Moment[]): any[] {
    let hours: any[] = [];

    const dateStrs = dates.map(d => d.format('YYYY-MM-DD'));

    this.rows.forEach(row => {
      const days = row.days.filter(day => dateStrs.includes(day.date.format('YYYY-MM-DD')));
      hours = hours.concat(days.map(day => day.hoursTotal));
    });

    return hours;
  }

  refreshChartData() {
    const periodDays = this.getPeriodDates();
    const hours = this.getPeriodDateTotals(periodDays);
    this.chartData.days = periodDays;
    this.chartData.hours = hours;
  }

  initChart() {
    this.chart = new Chart('canvas', {
      plugins: [chartJsPluginAnnotation],
      type: 'bar',
      data: {
        labels: this.chartData.days.map(d => d.format('ddd D.M.')),
        datasets: [
          {
            data: this.chartData.hours,
            backgroundColor: [],
            hoverBackgroundColor: [],
            fill: true
          }
        ]
      },
      options: {
        layout:{
          padding:{
            top:50 //Fixes the bug where tooltip cuts of when < 15h
          }
        },
        responsive: true,
        maintainAspectRatio: false,
        fontSize: 11,
        fontFamily: 'Roboto, \'Helvetica Neue\', sans-serif',
        legend: {
          display: false
        },
        tooltips: {
          displayColors: false,
          cornerRadius: 2,
          caretSize: 0,
          backgroundColor: 'rgb(97,97,97)', // Angular Material tooltip background color
          opacity: .9,
          xAlign: 'center',

          callbacks: {
            label: function (tooltipItem, data) {
              let label = data.datasets[tooltipItem.datasetIndex].label || '';

              if (label && label.length > 0) {
                label += ': ';
              }
              label += tooltipItem.yLabel + ' h';
              return label;
            }
          }
        },
        scales: {
          xAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.translator.translate('day')
            },
            ticks: {
              fontSize: 11,
              fontFamily: 'Roboto, \'Helvetica Neue\', sans-serif'
            }
          }],
          yAxes: [{
            display: true,
            scaleLabel: {
              display: true,
              labelString: this.translator.translate('hours')
            },
            ticks: {
              min: 0,
              max: this.chartMax,
              beginAtZero: true,
              fontSize: 11,
              fontFamily: 'Roboto, \'Helvetica Neue\', sans-serif'
            }
          }],
        },
        annotation: {
          annotations: [{
            drawTime: 'beforeDatasetsDraw',
            id: 'excepted-hours', // ID is optional
            type: 'line',
            mode: 'horizontal',
            scaleID: 'y-axis-0',
            value: environment.exceptedHoursPerDay,
            borderColor: '#CCC',
            borderWidth: 2,
            borderDash: [2, 2],
            borderDashOffset: 5
          }]
        }
      }
    });

    console.log(this.chart);
  }
  weekStartAndEnd(date: moment.Moment) {
    const day = DateUtils.getAllDaysInWeek(date)[0];

    return day.startOf('isoWeek').format('D.M.') + '-' + day.endOf('isoWeek').format('D.M.');
  }
  checkHasEntries(element: PayperiodWeekEntryRow, i: number): boolean {
    if (!environment.payperiod.markWeekends && [6, 7].includes(i)) {
      return false;
    }

    if (!element.days[i - 1]) {
      return;
    }

    if (element.days[i - 1].hoursTotal < environment.payperiod.markLessThanHours) {
      if ([6, 7].includes(i) && environment.payperiod.markWeekends) {
        return true;
      }

      return true;
    }

    return !element.days[i - 1].hasEntries;
  }
  openDay(element: PayperiodWeekEntryRow, dayIndex: number) {
    if (element && element.days[dayIndex]) {
      this.onDateClicked$.next(element.days[dayIndex].date);
      this.close();
    }
  }
  close() {
    this.dialogRef.close();
  }

  private invalidEntries(entries: PayperiodDayEntry[]): number {
    return entries.filter((e: PayperiodDayEntry) => e.hoursTotal < environment.exceptedHoursPerDay
      && this.isWeekday(e.date)).length; // Ignore weekends
  }
  private isWeekday(date: moment.Moment) {
    let day = date.get('day');
    day--; // Sunday: 0 -> -1, Monday: 1 -> 0 etc.

    if (day < 0) {
      day += 7; // For Sunday: -1 -> 6
    }

    return DateUtils.weekdayFilter(day); // Monday = 0 ... Friday = 4, Saturday = 5 & Sunday = 6
  }
  checkOutPeriod() {
    if (this.canCheckoutPeriod !== true) {
      return;
    }

    const confirm = this.dialog.open(ConfirmDialogComponent, {
      height: '250px',
      width: '400px',
      data: {}
    });
    const component = confirm.componentInstance;

    component.heading = this.translator.translate('ConfirmPeriodSubmit');
    component.desc1 = this.translator.translate('ConfirmPeriodSubmitDesc1');
    component.desc2 = this.translator.translate('ConfirmPeriodSubmitDesc2');
    component.cancel = this.translator.translate('Cancel');
    component.confirmButtonText = this.translator.translate('CheckOut');

    confirm.afterClosed().subscribe(result => {
      if (result === true) {
        forkJoin([this.apiSvc.checkOutPeriod(moment(this.date), this.data.userId)]).subscribe(() => {
          this.periodCheckedOut = true;
          //this.data.parentComponent.getSettings();
          //this.data.parentComponent.changeCalendarDate();

          const alertText = this.translator.translate('PayperiodCheckedoutSuccessfully');
          AlertService.snackbar(this.snackBar, alertText, null, true, environment.errorSnackbarDelay);
        },
          error => {
            // Error 401 (Unauthorized) -> show login dialog
            if (error.status === 401) {
              const dialog = this.loginService.loginDialog();
              forkJoin([dialog.afterClosed()]).subscribe(() => {
                this.checkOutPeriod();
              });
            } else if (error.status === 400) {
              const alertText = this.translator.translate('PayperiodAlreadyCheckedout');
              AlertService.snackbar(this.snackBar, alertText, null, true, environment.errorSnackbarDelay);
            } else {
              const alertText = this.translator.translate('Error') + ' ' + error.status + ' (' + error.statusText + ')';
              AlertService.snackbar(this.snackBar, alertText, null, true, environment.errorSnackbarDelay);
            }
          });
      }
    });
  }
  getLocalizedWeekDay(isoWeekDay: number) {
    return moment.weekdays(isoWeekDay);
  }

  downloadReport() {
    this.reportBtnEnabled = false;

    let subscribe;

    const snackBarRef: MatSnackBarRef<SimpleSnackBar> = AlertService.snackbar(this.snackBar, 'Ladataan raporttia palvelimelta...',
      null, false);

    /*snackBarRef.onAction().subscribe(() => {
      cancelled = true;
      subscribe.unsubscribe();
    });*/

    subscribe = this.apiSvc.getPeriodReport(this.date, this.data.userId).subscribe(event => {
      // Get headers
      const headers = event.headers,
        contentDisposition = headers.get('content-disposition');

        console.log(headers);
        console.log(event);


      // A default file name
      let fileName = 'Raportti palkkajaksolta ' + this.heading + '.pdf';

      // Check if the Content-Disposition header contains 'attachment' (= there will be also 'filename')
      if (contentDisposition && contentDisposition.includes('attachment')) {

        // Extract a filename from the header
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/,
          matches = regex.exec(contentDisposition);

        // Check if the filename exists and write it to fileName variable
        if (matches != null && matches[1]) {
          fileName = matches[1].replace(/['"]/g, '');
        }

        // Add .pdf extension if necessary
        if (!fileName.endsWith('.pdf')) {
          fileName += '.pdf';
        }
      }

      // Create blob
      const blob = new Blob([event.body], { type: 'application/pdf' });

      // Save blob to user's device
      saveAs(blob, fileName);

      // Notify the user while 'real' downloading starts
      AlertService.snackbar(this.snackBar, 'Raportti ladattu palvelimelta. Aloitetaan latausta laitteeseesi...', null, true, 3000);
    }, error => {
      // Error 401 (Unauthorized) -> show login dialog
      if (error.status === 401) {
        const dialog = this.loginService.loginDialog();
        forkJoin([dialog.afterClosed()]).subscribe(() => {
          this.downloadReport();
        });
      } else {
        console.error(error);
      }
      AlertService.snackbar(this.snackBar, 'Raportin lataaminen palvelimelta epäonnistui', null, true, 3000);
    }, () => {
      // Completed
      this.reportBtnEnabled = true;
    });
  }
}

export class PayperiodWeekEntryRow {
  isoWeek: number;
  days: PayperiodDayEntry[];
}

export class PayperiodDayEntry {
  date: moment.Moment = null;
  otherPeriod = false;
  hoursTotal = 0;
  hasEntries = false;
}
class PeriodRange{

  constructor(public start : moment.Moment, public end : moment.Moment){

  }
}
