import { Component } from '@angular/core';

@Component({
    templateUrl: 'login-page.component.html',
    styleUrls: ['./login-page.component.css', '../../../node_modules/metro-dist/css/metro-icons.css']
})

export class LoginPageComponent {
    constructor() { }
}
