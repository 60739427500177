import { Component, Input, OnInit, AfterViewInit, HostListener, AfterContentInit, Output, EventEmitter } from '@angular/core';
import { FormGroup,FormControl, Validator, ValidatorFn, Validators, FormBuilder } from '@angular/forms';

import { ProjectDayEntry } from '../weekentry.class';
import { HomeComponent } from '../home/home.component';
import { VALID } from '../../../node_modules/@angular/forms/src/model';
import { EntryUpdaterService } from '../entry-updater.service';
import { DayEntry } from '../entity';
import { BehaviorSubject } from 'rxjs';
import { distinct, distinctUntilChanged, skip} from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-lastu-form',
  templateUrl: './lastu-form.component.html',
  styleUrls: ['./lastu-form.component.css'],
})
export class LastuFormComponent implements OnInit{

  @Input()
  DayEntry$ : BehaviorSubject<any>;
  @Output()
  valueChanged$ : EventEmitter<any>;

  formControllers : FormGroup;

  currentDate : moment.Moment;

  readOnlyProperty: boolean = false;

  public get readOnly() : boolean{
    return this.readOnlyProperty;
  }
  public set readOnly(value : boolean){
    this.readOnlyProperty = value;
    if(this.formControllers != null){
      if(value){
        this.formControllers.disable();
        console.log("disable");
      }else{
        this.formControllers.enable();
        console.log("enable");
      }
      this.updateControllerStates();
    }


  }

  constructor(private formBuilder : FormBuilder) {
    this.valueChanged$ = new EventEmitter<any>();
    this.DayEntry$ = new BehaviorSubject<any>(null);
    this.DayEntry$.subscribe(entry => {
      if(entry === null || entry === undefined){
        this.formControllers = null;
        this.currentDate = null;
        return;
      }
      if(moment.isMoment(entry['day'])){
        this.currentDate = entry['day'];
      }else{
        this.currentDate = null;
      }
      this.buildForm(entry);

      this.updateControllerStates();
    });
  }
  private buildForm(entry : any){
    this.formControllers = this.formBuilder.group(entry, {updateOn: "blur"});

    let kmController =  this.formControllers.get('KM');
    let muutKulutController = this.formControllers.get('MuutKulut');
    let paivarahatController = new FormControl(entry.Paivarahat, {updateOn: "change"});
    let paivarahaTyyppiController = this.formControllers.get('PvaRahaTyyppi');
    let kuljetusController =  new FormControl(entry.kuljetus, {updateOn: "change"});
    let kuljetusTyyppiController = this.formControllers.get('KuljetusTyyppi');

    this.formControllers.setControl('Paivarahat',paivarahatController);
    this.formControllers.setControl('kuljetus', kuljetusController);

    kmController.valueChanges.subscribe(v => {
      if(v === null){
        kmController.setValue(0);
      }
    });
    muutKulutController.valueChanges.subscribe(v => {
      if(v === null){
       muutKulutController.setValue(0);
      }
    });
    paivarahatController.valueChanges.subscribe(v => {
      if(v === true){
        if(paivarahaTyyppiController.value === 0){
          paivarahaTyyppiController.setValue(1);
        }

      }
    });
    kuljetusController.valueChanges.subscribe(v => {
      if(v === true){
        if(kuljetusTyyppiController.value === 0){
          kuljetusTyyppiController.setValue(1);
        }
      }
    });

    this.formControllers.valueChanges.pipe(
       /*TODO This skips initial valueChange that is triggered when form is created.
       Research why this gets triggered on initial value*/
      skip(1),
      distinct()
    ).subscribe(v => {
      this.updateControllerStates();
      if(this.readOnly){
        return;
      }
      if(this.formControllers.valid){
        this.valueChanged$.emit(this.formControllers.getRawValue());
      }
    });

  }



  updateControllerStates(){
    if(this.formControllers == null){
      return;
    }

    this.updateLinkedControllerState('Paivarahat','PvaRahaTyyppi', 0);

    let kuljetusProperty = "kuljetus";
    this.updateLinkedControllerState(kuljetusProperty ,'KuljetusTyyppi', 0);
    this.updateLinkedControllerState(kuljetusProperty ,'KM', 0);
    this.updateLinkedControllerState(kuljetusProperty ,'MuutKulut', 0);
    this.updateLinkedControllerState(kuljetusProperty ,'KuljetusTyyppi', 0);
    this.updateLinkedControllerState(kuljetusProperty ,'MuutKulut', 0);
    this.updateLinkedControllerState(kuljetusProperty ,'MuutKulutSelite');
  }
  updateLinkedControllerState(parent : string, child : string, childDefaultValue = undefined){

    let value = this.formControllers.get(parent).value;
    let childControl = this.formControllers.get(child);
    if(this.readOnly){
      childControl.disable({emitEvent : false});
      return;
    }
    if(value === true){
      childControl.enable({emitEvent : false});
    }else{
      childControl.disable({emitEvent : false});
      if(childDefaultValue !== undefined){
        childControl.setValue(childDefaultValue, {emitEvent : false});
      }

    }

  }

  ngOnInit() {

  }

}
