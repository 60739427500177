import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class Translator {

    private lang: string;
    private translations = {}; // Key = id and value = translation

    constructor(private http: HttpClient) {
    }

    private initLang() {
        this.http.get('../assets/translations/' + this.lang + '.json').subscribe(result => {
            this.translations = result;
        },
        error => {
            // throw new Error('Error: ' + error.status + ', ' + error.statusText);
        });
    }

    set locale(lang) {
        this.lang = lang;
        this.initLang();
    }

    get locale() {
        return this.lang;
    }

    translate(id: string): string {
        if (this.translations[id] === undefined || this.translations[id] == null) {
            return id;
        }

        return this.translations[id];
    }
}
