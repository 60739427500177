import * as moment from 'moment';

export class User {
  public jwt: string;
}
export class Project{
  displayName : string;
  id : number;
  name : string;
  requiredWorkStageId : number;
  requireWorkstage : boolean;
  state : number;
}
export class WorkstageGroup{
  caption : string;
  letterCode : string;
  workStages : Workstage[];
}
export class Workstage{
  id : number;
  code : string;
  enabled : boolean;
  name : string;
}
export interface IWeekEntryRow {
  project : string;
  workstage :string;
  dayEntries: DayEntry[];

}
export interface IDayEntry {
  id: number;
  day: moment.Moment;
  hours: number;
  readOnly : boolean;
}
export class WeekEntryRow implements IWeekEntryRow{
  project : string;
  workstage :string;
  dayEntries: DayEntry[] = [];

  constructor() {
  }
}

export class DayEntry implements IDayEntry{
  id: number = null;
  day: moment.Moment;
  hours: number;
  readOnly: boolean = false;

}


export class PeriodInfo{
  periodSubmitted : boolean;
  submittedHours : number;
  requiredHours : number;
  startDate : moment.Moment;
  endDate : moment.Moment;
  missingRequiredEntries : any[];
}
