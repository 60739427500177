import { Component, OnInit, Output, Input, OnChanges, SimpleChanges, SimpleChange, ViewChild } from '@angular/core';
import { Observable, BehaviorSubject, Subscription, combineLatest } from 'rxjs';
import { startWith, filter, map, tap } from 'rxjs/operators';

import * as moment from 'moment';
import * as _ from 'lodash';
import { MatTableDataSource, MatAutocompleteSelectedEvent, MatHeaderRowDef, MatDialog } from '@angular/material';
import { ReactiveFormsModule, FormControl, FormGroup, FormArray, Validators, ValidatorFn, AbstractControl, Form, FormBuilder } from '@angular/forms';
import { EventEmitter } from '@angular/core';
import { WorkstageGroup, Project, Workstage, IWeekEntryRow, WeekEntryRow, IDayEntry, DayEntry } from '../entity';
import { WorkStage } from '../weekentry.class';
import { ContextMenuComponent } from '../contextmenu.component';
import { _fixedSizeVirtualScrollStrategyFactory } from '@angular/cdk/scrolling';
import { DateUtils } from '../DateUtils.class';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { Translator } from '../translator';
import { isUndefined } from 'util';



@Component({
  selector: 'app-hourtable',
  templateUrl: './hourtable.component.html',
  styleUrls: ['./hourtable.component.css', '../home/home.component.css', '../../../node_modules/metro-dist/css/metro-icons.css'],

})
export class HourtableComponent implements OnInit, OnChanges {

  @ViewChild(ContextMenuComponent)
  dayEntryContextMenu: ContextMenuComponent;

  @Input()
  projects$: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);

  @Input()
  workstageGroups$: BehaviorSubject<WorkstageGroup[]>;

  @Output()
  valueChange$: EventEmitter<any> = new EventEmitter<any>();

  @Output()
  dayCellClicked$: EventEmitter<DayCellClickEventArgs> = new EventEmitter<DayCellClickEventArgs>();

  @Output()
  rowDeleted$: EventEmitter<RowDeleteEventArgs> = new EventEmitter<RowDeleteEventArgs>();

  currentDateValue: moment.Moment;

  displayedColumns = [];
  public dayIndexes = [1, 2, 3, 4, 5, 6, 7]; //Indexes of isoWeekDay
  workstages$: BehaviorSubject<Workstage[]>;

  projectShortcuts: Project[];
  dataSource: MatTableDataSource<any>;

  rows: InternalRow[];

  sortedProjects$: BehaviorSubject<Project[]> = new BehaviorSubject<Project[]>([]);

  tableForm: FormArray;

  totalsOfColumns = [0, 5, 5, 6, 7, 8, 9, 1];

  hourOptions: number[] = [];

  rowIdCounter: number = 0;


  dateInfo: DateInfo[];

  isLoading: boolean;

  periodBoundary: [moment.Moment, moment.Moment];

  public totalHours : number;

  selectedCell :InternalCell;

  constructor(private readonly formBuilder: FormBuilder, private dialog: MatDialog, private translator: Translator) {
    this.displayedColumns = ['project', 'workstage', "1", "2", "3", "4", "5", "6", "7", "Action_Delete"];
    this.workstages$ = new BehaviorSubject<WorkStage[]>([]);
    this.dataSource = new MatTableDataSource();
    this.rows = [];

    this.tableForm = new FormArray([],
      { validators: [//this.duplicateCompositeValidator(),
        this.hourCountValidator()] }
    );
    this.generateHourOptions();
    this.tableForm.valueChanges.subscribe(data => {

      _.forEach(data, row => {
        row.weekEntryRow = this.rows.find(r => r.rowId === row.rowId).weekEntryRow;
      });
      this.valueChange$.emit(this.tableForm.value);
      /*let changes = this.changedRows.map(change => {
        return {
          row: change.row.controls.value,
          changedProperties: change.changedProperties
        };
      });
      this.valueChange$.emit(changes);*/
    });
    this.projects$.subscribe(projects => {
      this.projectShortcuts = projects.filter(project => project.requireWorkstage);

    });
    this.projects$.pipe(
      map(projects => _.orderBy(projects, 'state', 'desc'))
    ).subscribe(p => this.sortedProjects$.next(p));
    this.currentDate = moment();
  }
  public setLoading(isLoading: boolean) {
    this.isLoading = isLoading;
  }
  public get currentDate(): moment.Moment {
    return this.currentDateValue;
  }

  /**
   * Set the current date.
   * Warning! This will reset the read only days!
   */
  public set currentDate(value: moment.Moment) {
    if (value == null) {
      this.currentDateValue = null;
      this.dateInfo = null;
      return;
    }

    let days = DateUtils.getAllDaysInWeek(value);
    let weekBoundaries = DateUtils.getWeekStartAndEnd(value);
    this.periodBoundary = DateUtils.getPayperiodStartAndEnd(value);
    this.dateInfo = [];
    for (let i = 0; i < 7; i++) {
      this.dateInfo.push({
        day: days[i],
        isReadOnly: false,
        isNextPeriod: days[i].isAfter(this.periodBoundary[1]),
      });
    }

    this.currentDateValue = value;

  }

  generateHourOptions() {
    for (let i = 7.5; i > 0; i -= 0.5) {
      this.hourOptions.push(i);
    }
  }
  triggerDeleteRow(row: InternalRow) {
    //Use loop to find tableForm index for row.
    for (let i = 0; i < this.tableForm.length; i++) {
      if (this.tableForm.at(i) === row.controls) {
        if (this.rowHasReadonlyEntries(row)) {
          console.log("Can't delete row which has readonly entries");
          return;
        }
        const subs = this.confirmDelete().subscribe(
          result => {
            if (result === true) {
              const args = new RowDeleteEventArgs();
              args.weekEntryRow = row.weekEntryRow;
              this.rowDeleted$.emit(args);
              this.rows.splice(this.rows.indexOf(row), 1);
              this.tableForm.removeAt(i);
              this.dataSource.data = this.rows;
              subs.unsubscribe();
            }
          }
        );
        return;
      }
    }
    console.error("Delete failed: control not found");

  }
  private rowHasReadonlyEntries(row: InternalRow): boolean {
    //Check if there are any entries with id that belong to read only day.
    for (let k = 0; k < this.dateInfo.length; k++) {
      if (this.dateInfo[k].isReadOnly) {
        if (row.controls.get('day' + (k + 1)).get('dayEntry').value.id !== null) {
          return true;
        }

      }
    }
    return false;
  }
  private confirmDelete(): Observable<any> {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent);
    const component = confirmDialog.componentInstance;

    component.heading = this.translator.translate('DeleteRowDialogHeading');
    component.desc1 = this.translator.translate('DeleteRowDialogDesc1');
    component.desc2 = this.translator.translate('DeleteRowDialogDesc2');
    component.confirmButtonText = this.translator.translate('DeleteRowDialogConfirm');
    return confirmDialog.afterClosed();
  }
  public get isValid() {
    return this.tableForm.valid;
  }
  public get errors(){
    return this.tableForm.errors;
  }

  triggerDayCellClick(cell : InternalCell, dayIndex: number) {
    if(this.selectedCell){
      this.clearCellSelection();
    }
    this.selectedCell = cell;
    cell.selected = true;
    this.dayCellClicked$.emit({
      row: cell.parentRow.controls.value,
      dayProperty: "day" + dayIndex
    });
  }
  public clearCellSelection(){
    if(this.selectedCell){
      this.selectedCell.selected = false;
      this.selectedCell = null;
    }

  }
  public addRow(rowValues: IWeekEntryRow) {
    let row = this.generateRowControls(rowValues);
    this.rows.push(row);
    this.tableForm.push(row.controls);
    this.dataSource.data = this.rows;
    this.updateTotals();
    this.updateRowPermissions();
  }
  public addRows(rowValues: IWeekEntryRow[]) {
    _.forEach(rowValues, rowValue => {
      let row = this.generateRowControls(rowValue);
      this.rows.push(row);
      this.tableForm.push(row.controls);
    });

    console.time("Replace data");
    this.dataSource.data = this.rows;

    console.timeEnd("Replace data");
    this.updateTotals();
    this.updateRowPermissions();
  }

  private triggerDayContextMenu(row: InternalRow, dayIndex: number, event: MouseEvent) {
    event.preventDefault();
    if (row.readOnly) {
      return;
    }
    let hourControl = row.controls.get('day' + dayIndex).get('hours');
    //Ignore invalid value and last day of the week.
    if (!hourControl.valid || dayIndex == 7) {
      return;
    }
    let contextMenuItems = [
      {
        title: 'Kopioi loppuviikolle',
        callback: (): void => {
          let hours = hourControl.value;
          this.iterateRowDayControls(row, (dayControl, dIndex) => {
            dayControl.setValue(hours);
          }, dayIndex);
        }
      }
    ];
    if (!DateUtils.isWeekend(dayIndex)) {
      contextMenuItems.push({
        title: 'Kopioi arkipäiville',
        callback: (): void => {
          let hours = hourControl.value;
          this.iterateRowDayControls(row, (dayControl, dIndex) => {
            dayControl.setValue(hours);
          }, dayIndex, 5);

        }
      });
    }

    this.dayEntryContextMenu.items = contextMenuItems;
    this.dayEntryContextMenu.open(event);
  }
  private updateRowPermissions() {
    const hasReadonlyDays = this.hasReadOnlyDays();
    console.log("Read only days: " + hasReadonlyDays);
    _.forEach(this.rows, row => {
      if(hasReadonlyDays){
        let hasReadOnlyDayEntries = false;
        _.forEach(row.weekEntryRow.dayEntries, dayEntry => {
          if(dayEntry === undefined){

            return;
          }
          if(dayEntry.id &&
            this.dateInfo[dayEntry.day.isoWeekday()-1].isReadOnly){

            console.log("read only ", row, dayEntry);
            row.canEditProject = false;
            hasReadOnlyDayEntries = true;
            return false;
          }
        });
        if(!hasReadOnlyDayEntries){
          row.canEditProject = true;
        }
      }else{
        row.canEditProject = true;
      }
    });
  }
  private iterateRowDayControls(row: InternalRow, iteratee: (dayControl: AbstractControl, dayIndex: number) => void, startDay: number = 1, endDay = 7) {

    for (let i = startDay; i <= endDay; i++) {
      let control = row.controls.get('day' + i).get('hours');
      iteratee(control, i);
    }
  }
  private generateRowControls(rowValues: IWeekEntryRow): InternalRow {

    let row = new InternalRow();
    row.rowId = ++this.rowIdCounter;
    row.weekEntryRow = rowValues;
    row.cells = [];
    const projectControl = new FormControl(rowValues.project, { validators: [Validators.required, this.validProjectValidator()] });

    const workstageControl = new FormControl(rowValues.workstage, { validators: [Validators.required, this.validWorkstageValidator()] });

    //Updates the workstage readonly field. TODO Handle this some other means.
    if (rowValues.project !== undefined) {
      const project = this.getProjectByDisplayName(rowValues.project);
      if (project) {
        if (project.requireWorkstage) {
          row.projectRequiredWorkstage = true;
        }
        if (project.state == 0) {
          row.readOnly = true;
        }
      }

    }
    row.controls = new FormGroup({
      project: projectControl,
      workstage: workstageControl,
      rowId: new FormControl(row.rowId)
    }, { updateOn: 'blur', validators: [this.duplicateCompositeValidator()] });
    //Initialize empty filtered projects
    row.filteredProjects$.next([]);
    const workstageFilter = combineLatest(this.workstageGroups$, row.workstageFilterString$).pipe(
      map(([groups, filter]) => {
        return this.workstageFilter(groups, filter);
      }),
      map(([groups, filter]) => groups)
    );

    row.filteredWorkstages$ = workstageFilter;
    console.time("Generate date controls");
    this.dayIndexes.forEach(isoWeekDay => {
      let propertyId = "day" + isoWeekDay.toString();
      let d = rowValues.dayEntries[isoWeekDay];
      const dayCell = new InternalCell();
      dayCell.parentRow = row;
      dayCell.isoDayNumber = isoWeekDay;
      dayCell.dayEntry = d;
      const dayControl = this.formBuilder.group({
        dayEntry: d,
        hours: [d.hours, { validators: [Validators.required, this.hourEntryValueValidator()] }]
      });
      dayCell.dayControl = dayControl;
      row.cells.push(dayCell);
      let hourEntryControl = dayControl.get("hours") as FormControl;
      hourEntryControl.valueChanges.subscribe(data => {

        this.updateTotals();
      });
      row.controls.addControl(propertyId, dayControl);
    });

    projectControl.valueChanges.subscribe(data => {
      const project = this.getProjectByDisplayName(data);

      if (project === undefined) {
        row.projectRequiredWorkstage = false;
        workstageControl.setValue("", { emitEvent: true });
        return;
      }

      if (project.requireWorkstage) {
        const workstage = this.getWorkstageById(project.requiredWorkStageId);
        workstageControl.setValue(workstage.name, { emitEvent: true });
        row.projectRequiredWorkstage = true;
      } else {

        //Check if previous project required workstage and reset the workstage if it did
        if (row.previousProject !== undefined && row.previousProject.requireWorkstage) {
          workstageControl.setValue("", { emitEvent: true });
        }
        row.projectRequiredWorkstage = false;
      }
      row.previousProject = project;
      workstageControl.markAsTouched();

    });
    workstageControl.valueChanges.subscribe(data => {
      projectControl.markAsTouched();
    });

    console.timeEnd("Generate date controls");
    return row;
  }

  private workstageFilter(groups: WorkstageGroup[], filterString: string): [WorkstageGroup[], string] {

    groups = groups.filter(g => g.letterCode !== "");
    if (filterString != null && filterString != "") {
      filterString = filterString.toLowerCase();
      groups = _.cloneDeep(groups); //Don't touch original
      groups = groups.filter(g => {

        g.workStages = g.workStages.filter(w => w.name.toLowerCase().includes(filterString));
        return g.workStages.length > 0;
      });
    }
    return [groups, filterString];
  }
  private updateTotals() {
    this.totalsOfColumns = [0, 0, 0, 0, 0, 0, 0, 0];
    _.forEach(this.rows, row => {
      _.forEach(this.dayIndexes, dayIndex => {
        let hours = row.controls.get('day' + dayIndex).get('hours').value;
        this.totalsOfColumns[dayIndex] += hours;
      })
    });

    this.totalHours = _.sum(this.totalsOfColumns);
  }
  private filterProjects(filterString: string, row: InternalRow) {
    filterString = filterString.toLowerCase();
    if (filterString.length > 2) {
      let filtered = this.sortedProjects$.getValue().filter(p => {

        return p.displayName.toLowerCase().includes(filterString)

      });
      row.filteredProjects$.next(filtered);
    } else {
      row.filteredProjects$.next([]);
    }
  }
  searchProject(filterString: string, row: InternalRow) {
    this.filterProjects(filterString, row);
  }
  projectSelected(selectedElement: MatAutocompleteSelectedEvent, row: InternalRow) {

    row.controls.get('project').setValue(selectedElement.option.value);

  }
  private forceValidate(){

  }
  public clear() {
    this.rows = [];
    this.dataSource.data = this.rows;
    this.tableForm.controls = [];
    this.updateTotals();
  }
  getRows() {
    return this.tableForm.value;
  }

  ngOnInit() {

  }

  ngOnChanges(changes: SimpleChanges): void {
    /*if (changes["weekEntries$"] && this.weekEntries$) {
      if(this.weekEntrySubscription){
        this.weekEntrySubscription.unsubscribe();
      }
      this.weekEntrySubscription = this.weekEntries$.subscribe(
        entries => this.generateControls(entries)
      );
    }*/
    if (changes["workstageGroups$"] && this.workstageGroups$) {

      this.workstageGroups$.subscribe(
        groups => {
          const stages = _.flatMap(groups, g => g.workStages);
          this.workstages$.next(stages);
        }
      );
    }
  }
  onProjectSearch() {

  }

  generateControls(entries: IWeekEntryRow[]) {
    if (entries === null || entries === undefined) {
      throw new ReferenceError("Entries is null or undefined");
    }

  }
  isWeekend(isoWeekDay: number) {
    return isoWeekDay == 6 || isoWeekDay == 7;
  }
  getLocalizedWeekDay(isoWeekDay: number) {
    return moment.weekdays(isoWeekDay);
  }


  duplicateCompositeValidator(): ValidatorFn {
    return (controls: FormArray): { [key: string]: any } | null => {
      //return { 'identityRevealed': true };
      let composites = [];
      for (let i = 0; i < controls.length; i++) {

        let project = controls.at(i).get('project').value;
        let workstage = controls.at(i).get('workstage').value;
        let key = project + workstage;
        if (composites.indexOf(key) === -1) {
          composites.push(key);
        } else {
          return { duplicateFound: true, project, workstage };
        }
      }
      return null;
    };
  }
  validProjectValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
      let project = this.getProjectByDisplayName(control.value);
      if (project === undefined) {
        return { invalidProject: true, error: "Project does not exist" };
      } else {
        if (!control.pristine && project.state !== 1) {
          return { invalidProject: true, error: "Invalid project. Project is not active" };
        }
      }
      return null;
    };
  }
  validWorkstageValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
      let workstage = this.getWorkstageByName(control.value);
      if (workstage === undefined) {
        return { invalidWorkstage: true };
      }
      return null;
    };
  }
  hourEntryValueValidator(): ValidatorFn {
    return (control: FormControl): { [key: string]: any } | null => {
      let hours = control.value;
      if (hours < 0 || hours > 24) {
        return { invalidHourValue: true };
      }
      let decimal = hours - Math.floor(hours);
      if (decimal === 0.5 || decimal === 0) {

      } else {
        console.log("invalidHourValue");
        return { invalidHourValue: true };
      }
    };
  }
  hourCountValidator(): ValidatorFn {
    return (controls: FormArray): { [key: string]: any } | null => {
      this.updateTotals();
      for (let i = 1; i < this.totalsOfColumns.length; i++) {
        if (this.totalsOfColumns[i] > 24) {
          return { invalidHourCount: true };
        }
      }
      return null;
    };
  }
  public setDayReadOnly(day: moment.Moment | number, value: boolean) {
    let isoWeekDay: number;
    if (moment.isMoment(day)) {
      isoWeekDay = day.isoWeekday();
    } else {
      isoWeekDay = day;
    }
    this.dateInfo[isoWeekDay - 1].isReadOnly = value;
    this.updateRowPermissions();
  }
  public setCellMoreDataFlag(dayEntry : IDayEntry, value: boolean){
    let found = false;
    _.forEach(this.rows, row => {
      _.forEach(row.cells, dayCell => {
        if(dayCell.dayEntry == dayEntry){
          dayCell.hasMoreDataFlag = value;
          found = true;
          return false;
        }
      });
      if(found){
        return false;
      }
    });
    if(!found){
      console.error("Cell not found");
    }

  }
  public isDayReadOnly(day: moment.Moment | number) {
    let isoWeekDay: number;
    if (moment.isMoment(day)) {
      isoWeekDay = day.isoWeekday();
    } else {
      isoWeekDay = day;
    }
    return this.dateInfo[isoWeekDay - 1].isReadOnly;
  }
  public hasReadOnlyDays() : boolean{
    return this.dateInfo.find(d => d.isReadOnly) !== undefined;
  }
  hourAutoCompleteSelect(hourOption: number, row: InternalRow, dayIndex: number) {
    let dayControl = row.controls.get('day' + dayIndex);
    let hourControl = dayControl.get('hours');

    hourControl.setValue(hourOption);
  }
  getProjectByDisplayName(name: string) {
    return this.projects$.value.find(item => item.displayName === name);
  }
  getWorkstageByName(name: string) {
    return this.workstages$.value.find(item => item.name === name);
  }
  getProjectById(id: number) {
    return this.projects$.value.find(item => item.id === id);
  }
  getWorkstageById(id: number) {
    return this.workstages$.value.find(item => item.id === id);
  }


}

class DateInfo {
  day: moment.Moment;
  isReadOnly: boolean = false;
  isNextPeriod: boolean = false;

}

class InternalRow {
  public controls: FormGroup;
  filteredProjects$: BehaviorSubject<any>;
  filteredWorkstages$: Observable<WorkstageGroup[]>;
  projectRequiredWorkstage: Boolean;
  previousProject: Project;
  workstageFilterString$: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  weekEntryRow: IWeekEntryRow;
  rowId: number;
  readOnly: boolean;
  canEditProject: boolean;
  cells : InternalCell[];
  constructor() {
    this.filteredProjects$ = new BehaviorSubject<any>([]);

  }

}
class InternalCell{
  parentRow : InternalRow;
  dayEntry : IDayEntry;
  isoDayNumber : number;
  dayControl : AbstractControl;
  hasMoreDataFlag : boolean = false;
  selected : boolean = false;
}
export class RowDeleteEventArgs {
  weekEntryRow: IWeekEntryRow;
}
export class CellChangeEventArgs {
  weekEntryRow: IWeekEntryRow;

}
export class ValueChangeEventArgs {

  control: FormControl;
  rows: WeekEntryRow[];
  properties: string[];
  row: any;
  isValid: boolean;
}

export class DayCellClickEventArgs {
  row: any;
  dayProperty: string;
}

