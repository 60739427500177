import { MatDialogConfig, MatDialog } from '@angular/material';
import { Injectable } from '@angular/core';
import { LoginDialogComponent } from './alert.service';
import {SessionService} from './session.service';
import * as jwtDecode from 'jwt-decode';

@Injectable()
export class LoginService {

    constructor(private matDialog: MatDialog, private session: SessionService) {   }

    loginDialog() {
        const dialogConfig = new MatDialogConfig();

        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.width = '90%';
        dialogConfig.maxWidth = 425;
        dialogConfig.id = 'loginDialog';

        if (!this.matDialog.getDialogById('loginDialog')) {
            const dialog = this.matDialog.open(LoginDialogComponent, dialogConfig);

            return dialog;
        }

        return undefined;
    }

    isAuthenticated(): boolean {
        if (localStorage.getItem('jwt') && localStorage.getItem('jwt') != null) {
            // logged in so return true
            const jwt = localStorage.getItem('jwt');
            const token = jwtDecode(jwt);
            this.session.userId = token['uid'];
            this.session.userLevel = token['lvl'];
            this.session.userName = token['un'];
            return true;
        }

        return false;
    }
}
