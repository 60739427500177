import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, Validators } from '@angular/forms';
import { ApiService } from '../Api.service';
import { MatSnackBar, MatDialog } from '@angular/material';
import { AlertService } from '../_services/alert.service';

import * as jwtDecode from 'jwt-decode';
import { Translator } from '../translator';
import { environment } from '../../environments/environment';
import { SessionService } from '../_services/session.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'login-form',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.css', '../../../node_modules/metro-dist/css/metro-icons.css']
})

export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;
  usernameControl: FormControl;
  passwordControl: FormControl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService,
    public snackBar: MatSnackBar,
    private matDialog: MatDialog,
    public translator: Translator,
    public sessionService : SessionService) {
    translator.locale = environment.locale.short;
  }

  ngOnInit() {
    // reset login status
    this.sessionService.logout();
    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    this.usernameControl = new FormControl('', [Validators.required]);
    this.passwordControl = new FormControl('', [Validators.required]);
  }

  private validateNameOrPass(value: any) {
    return !value || (value && value.trim().length === 0);
  }

  login() {
    this.loading = true;

    if (this.validateNameOrPass(this.model.username) || this.validateNameOrPass(this.model.password)) {
      this.handleError({ message: this.translator.translate('LoginErrorEmptyFields') }); // 'Username or password is empty'

      this.loading = false;
      return;
    }

    this.apiService.login(this.model.username, this.model.password).subscribe(
      data => {
        console.log(data);

        this.loading = false;



        console.log('Login success');

        const dialog = this.matDialog.getDialogById('loginDialog');

        if (dialog) {
          dialog.close();
        } else {
          this.sessionService.setToken(data["token"]);
          this.router.navigate([this.returnUrl]);
        }

      },
      error => {
        this.loading = false;
        console.log('Login failed');

        console.log(error);

        let msg;

        if (error.status === 401) {
          msg = this.translator.translate('LoginErrorIncorrectCredentials');
        } else {
          msg = this.translator.translate('Error') + ' ' + error.status + ' (' + error.statusText + ')';
        }

        this.handleError({ message: msg });
      });
  }

  handleError(error) {
    AlertService.snackbar(this.snackBar, error.message,null);
  }
}
