import { Component, Directive, ElementRef, AfterViewInit, OnInit, DoCheck  } from '@angular/core';
import * as moment from 'moment';
import {FormControl} from '@angular/forms';
import {Observable,  forkJoin } from 'rxjs';
import {startWith, map} from 'rxjs/operators';
import { MatAutocompleteSelectedEvent } from '@angular/material';
import {MatTableDataSource} from '@angular/material';
import { ApiService } from './Api.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css', '../../node_modules/metro-dist/css/metro-icons.css']
})
export class AppComponent {

}

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {
  constructor(el: ElementRef) {
    el.nativeElement.style.backgroundColor = 'yellow';
  }
}
