import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable()
export class SocketService {
    public readonly SERVER_URL = environment.wsUrl + '?access_token=' + localStorage.getItem('jwt');

    private socket: WebSocket;

    constructor() {
        // this.connect();
    }

    public connect(): void {
        this.socket = new WebSocket(this.SERVER_URL);
    }

    public send(message: string | object): void {
        if (typeof message === 'object') { message = JSON.stringify(message); }

        this.socket.send(message);
    }

    public onOpen(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.onopen = () => observer.next();
        });
    }

    public onMessage(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.onmessage = (data: any) => observer.next(data.data);
        });

    }

    public onClose(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.onclose = () => observer.next();
        });
    }

    public onError(): Observable<any> {
        return new Observable<any>(observer => {
            this.socket.onerror = (error: any) => observer.next(error);
        });
    }

    public close(): void {
        this.socket.close();
    }
}
