import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-retry-dialog',
  templateUrl: './connection-retry-dialog.component.html',
  styleUrls: ['./connection-retry-dialog.component.css']
})
export class ConnectionRetryDialogComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
