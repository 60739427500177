import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';
import { FormsModule, ReactiveFormsModule } from '@angular/forms'; // <-- NgModel lives here
import { RouterModule, Routes, Router } from '@angular/router';
import * as metrojs from 'metro-dist/js/metro.js';
import { AppComponent, HighlightDirective } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  MatInputModule, MatFormFieldModule, MatAutocompleteModule, MatTableModule,
  MatButtonModule, MAT_LABEL_GLOBAL_OPTIONS,
  MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, MatSelectModule,
  MatDatepickerModule, MatNativeDateModule, MatRadioModule,
  MatSnackBarModule, MatProgressSpinnerModule, MatDialogModule, MatCheckboxModule, MatIconRegistry, MatIconModule, MatTooltipModule, MatBadgeModule
} from '@angular/material';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import { MatMenuModule } from '@angular/material/menu';
import { HttpClientModule } from '@angular/common/http';
import { AuthGuard } from './_guards/index';
import { ApiService } from './Api.service';
import { SessionService } from "./_services/session.service";
import { routing } from './app.routing';
import { AlertService, LoginDialogComponent } from './_services/alert.service';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { LoginPageComponent } from './login-page/login-page.component';
import { LoginService } from './_services/login.service';
import { DayDialogComponent } from './home/daydialog.component';
import { DateRangePickerComponent } from './date-range-picker/date-range-picker.component';
import { LastuFormComponent } from './lastu-form/lastu-form.component';
import { PayperiodDialogComponent } from './payperiod-dialog/payperioddialog.component';
import { environment } from '../environments/environment';
import { Translator } from './translator';
import { ContextMenuComponent } from './contextmenu.component';
import { PayperiodReminderDialogComponent } from './payperiod-reminder-dialog/payperiod-reminder-dialog.component';
import { SocketService } from './_services/socket.service';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { HourtableComponent } from './hourtable/hourtable.component';
import { EntryUpdaterService } from './entry-updater.service';
import { MomentModule } from 'ngx-moment';
import { ConnectionRetryDialogComponent } from './connection-retry-dialog/connection-retry-dialog.component';
import { GlobalErrorHandler } from './GlobalErrorHandler';

registerLocaleData(localeFi, 'fi');

@NgModule({
  declarations: [
    AppComponent,
    ConfirmDialogComponent,
    ContextMenuComponent,
    DateRangePickerComponent,
    DayDialogComponent,
    HighlightDirective,
    HomeComponent,
    LastuFormComponent,
    LoginComponent,
    LoginPageComponent,
    LoginDialogComponent,
    PayperiodDialogComponent,
    PayperiodReminderDialogComponent,
    HourtableComponent,
    ConnectionRetryDialogComponent
  ],
  entryComponents: [
    ConfirmDialogComponent,
    ContextMenuComponent,
    DayDialogComponent,
    LoginDialogComponent,
    PayperiodDialogComponent,
    PayperiodReminderDialogComponent
  ],
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatInputModule,
    MatMenuModule,
    MatMomentDateModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatSnackBarModule,
    MatTableModule,
    MatRadioModule,
    MatIconModule,
    MatTooltipModule,
    MatBadgeModule,
    ReactiveFormsModule,
    routing,
    MomentModule

  ],
  providers: [
    {
      provide : ErrorHandler,
      useClass : GlobalErrorHandler
    },
    ApiService,
    AlertService,
    ContextMenuComponent,
    SessionService,
    AuthGuard,
    LoginService,
    MatIconRegistry,
    SocketService,
    Translator,
    EntryUpdaterService,
    { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: { float: 'auto' } },
    { provide: MAT_AUTOCOMPLETE_DEFAULT_OPTIONS, useValue: { float: 'autoActiveFirstOption' } },
    { provide: LOCALE_ID, useValue: environment.locale.short }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
  // Diagnostic only: inspect router configuration
  constructor(router: Router) {
    console.log('Routes: ', JSON.stringify(router.config, undefined, 2));
  }
}


