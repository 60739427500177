import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Translator } from '../translator';
import { environment } from '../../environments/environment';

@Component({
    template: `
    <h1 mat-dialog-title>{{heading}}</h1>
    <mat-dialog-content>
        <div id="confirm-desc"><p>{{desc1}}</p><p>{{desc2}}</p></div>
    </mat-dialog-content>
    <mat-dialog-actions>
      <button mat-stroked-button color="primary" (click)="confirm(true)" tabindex="-1">{{confirmButtonText}}</button> <button mat-button (click)="confirm(false)">{{cancel}}</button>
    </mat-dialog-actions>
  `,
  styles: ['#confirm-desc { font-family: Roboto,"Helvetica Neue",sans-serif !important; }', 'mat-dialog-actions > button:not(:first-child) { margin-left: 0.75em }']
})
export class ConfirmDialogComponent {

    heading: string;
    desc1: string;
    desc2: string;
    cancel: string;
    confirmButtonText: string;

    constructor(private dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data, private translator: Translator) {

        this.translator.locale = environment.locale.short;
        this.cancel = (this.cancel) ? this.cancel : translator.translate('Cancel');
        this.confirmButtonText = (this.confirmButtonText) ? this.confirmButtonText : 'Ok';
    }

    confirm(result: boolean = false) {
        this.dialogRef.close(result);
    } 
}