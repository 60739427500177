import { Injectable } from '@angular/core';
import { ApiService } from './Api.service';

@Injectable()
export class EntryUpdaterService {

  private trackers : ITracker[];

  constructor(private apiService : ApiService) { }


  public updateEntry(changedObject, changedProperties){

  }
  public addEntry(entry){

  }

  public hasPendingUpdates() : boolean{
    return true;
  }
  public retry(){

  }
}
class UpdateEntryTracker implements ITracker{
  changedObject;
  changedProperties;

}
class AddEntryTracker implements ITracker{

}
interface ITracker{

}
