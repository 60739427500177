import { Injectable } from '@angular/core';
import * as jwtDecode from 'jwt-decode';


@Injectable()
export class SessionService {
    userId: number = null;
    userLevel: number = null;
    userName : string = null;
    jwtToken : string;

    constructor() {
      this.readToken(localStorage.getItem('jwt'));



    }
    public get token(){
      return localStorage.getItem('jwt');
    }
    public setToken(token : string){
      localStorage.setItem('jwt', token);
      this.jwtToken = token;
      this.readToken(token);
    }
    public logout(){
      this.setToken(null);
      localStorage.removeItem('jwt');
    }
    private readToken(token : string){
      if(token == null || token == undefined){
        this.userId = null;
        this.userLevel = null;
        this.userName = null;

      }else{
        const tokenData = jwtDecode(token);
        this.userId = tokenData['uid'];
        this.userLevel = tokenData['lvl'];
        this.userName = tokenData['un'];
      }

      console.log("Current user level: " + this.userLevel + " Id = " + this.userId);
    }
    get isAllowedToAccessOtherUsers() : boolean{
      return this.userLevel == 1;
    }

}
