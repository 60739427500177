import { Component, Inject } from '@angular/core';
import { MatSnackBar, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { Translator } from '../translator';
import { PayperiodDialogComponent } from '../payperiod-dialog/payperioddialog.component';

@Component({
  templateUrl: './payperiod-reminder-dialog.component.html',
  styleUrls: ['./payperiod-reminder-dialog.component.css', '../../../node_modules/metro-dist/css/metro-icons.css'],
})

export class PayperiodReminderDialogComponent {
  periodCheckedOut: boolean;
  
  constructor(private dialogRef: MatDialogRef<PayperiodReminderDialogComponent>, public snackBar: MatSnackBar, public translator: Translator, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) private data) {
    this.periodCheckedOut = false;
  }
  checkOutPeriod() {
    this.dialog.open(PayperiodDialogComponent, {
      height: 'auto',
      width: '950px',
      data: this.data
    });
  }

  close() {
    this.dialogRef.close();
  }
}